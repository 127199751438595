import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
} from "firebase/firestore";
import { auth, db } from "../firebase";
import { useEffect, useState } from "react";
import { RColumn, RRow, RText, Space } from "../compnents/otherCOmps";
import { ContainerBorder, TabButton } from "../compnents/compes";
import { format } from "date-fns";

export default function ({ Head }) {
  const [deposits, setDepoits] = useState([]);
  const [widths, setWidths] = useState([]);

  const [viewType, setViewType] = useState("dep");

  useEffect(() => {
    getDeposits();
    getWidths();
  }, []);

  async function getDeposits() {
    const depRef = collection(
      db,
      `users/${auth.currentUser.uid}/np-dep-history`
    );
    const q = query(depRef, orderBy("date", "desc"), limit(10));
    const depositsDoccs = await getDocs(q);

    if (depositsDoccs.empty) return;

    let deps = [];
    depositsDoccs.docs.forEach((doc) => {
      deps.push({ ...doc.data(), id: doc.id });
    });

    setDepoits(deps);
  }

  async function getWidths() {
    const depRef = collection(
      db,
      `users/${auth.currentUser.uid}/np-wid-history`
    );
    const q = query(depRef, orderBy("date", "desc"), limit(10));
    const depositsDoccs = await getDocs(q);

    if (depositsDoccs.empty) return;

    let deps = [];
    depositsDoccs.docs.forEach((doc) => {
      deps.push({ ...doc.data(), id: doc.id });
    });
    console.log(deps);
    setWidths(deps);
  }

  function SingleHistory({ dep }) {
    return (
      <ContainerBorder>
        <RColumn horizontalAlign="start">
          <RRow horizontalAlign="space-between" width={"100%"}>
            <RText
              color={
                ["partially_paid", "confirmed", "finished"].includes(dep.status)
                  ? "green"
                  : dep.status === "failed"
                  ? "red"
                  : "white"
              }
            >
              {["partially_paid", "confirmed", "finished"].includes(dep.status)
                ? "Confirmed"
                : dep.status === "failed"
                ? "Failed"
                : "Waiting"}
            </RText>
            <RText size="14px" color="grey">
              {format(dep.date, "dd MMM yyyy HH:mm")}
            </RText>
          </RRow>
          <Space height="6px" />

          <RText>
            {dep.amount} {dep.currency.toUpperCase()}
          </RText>
        </RColumn>
      </ContainerBorder>
    );
  }
  return (
    <>
      <Head>History</Head>
      <Space />
      <RRow horizontalAlign="space-evenly">
        <TabButton
          selected={viewType === "dep"}
          onClick={() => setViewType("dep")}
        >
          Deposit
        </TabButton>
        <TabButton
          selected={viewType === "wid"}
          onClick={() => setViewType("wid")}
        >
          Widthdraw
        </TabButton>
      </RRow>
      <Space />
      {viewType === "dep" && (
        <>
          {deposits.map((dep) => (
            <SingleHistory dep={dep} key={dep.id} />
          ))}
        </>
      )}
      {viewType === "wid" && (
        <>
          {widths.map((dep) => (
            <SingleHistory dep={dep} key={dep.id} />
          ))}
        </>
      )}
    </>
  );
}
