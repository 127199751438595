import { useLocation, useNavigate } from "react-router-dom";
import { EmojiPanel, PrimaryBtn, RColumn, RRow, RText, RTitile, ScorePanel, SecondaryBtn, Space } from "../compnents/otherCOmps";
import { telegramID, tgUsernameGlob } from "../aStartingPage";

import React, { useEffect, useRef, useState } from "react";
import { off, onChildChanged, onDisconnect, onValue, ref, set } from "firebase/database";
import { auth, db, functions, rdb } from "../firebase";
import { ColorPalette, isTest } from "../global/const";
import { httpsCallable } from "firebase/functions";
import { showErrorNotification, showSuccessNotification } from "../compnents/errorMessagesComp";
import { collection, getDoc, getDocs, query, where } from "firebase/firestore";




export default function PvpMovesGames(){
    let [h, setH] = useState(window.innerHeight);
    const nav = useNavigate();
    const location = useLocation();
    const {gameID, price, url, players_count,level, players, isCasa} = location.state;

    const [youWin, setYouWin] = useState(false);
    const [gameFinished, setGameFinisged] = useState(false);
    
    const [opponentConnected, setOpponentConnected] = useState(true);


    const urlToIndexHtml = {
      "uno": `https://uno.thegamehub.gg:3005/index.html?roomID=${gameID}&playerID=${auth.currentUser.uid}&playerName=${auth.currentUser.displayName}&numOfPlayers=${players_count}&isHost=${isCasa}`,
    }

    const urlToCallWin = []

    const timeTimerStart = 30;

    const [canClose, setCanClose] = useState(false);

   // const connectionRef =  ref(rdb, "active_games/games_move/"+ gameID + "/connected");
  //  const singleConnectedRef = ref(rdb, "active_games/games_move/"+ gameID + "/connected/" + auth.currentUser.uid);
    const OpponentEmojiRef = ref(rdb, "active_games/games_move/"+ gameID + "/emoji");
    const [timer, setTimer] = useState(timeTimerStart + 30);
    const [isTimerPanelOpen, setIsTimerPanelOpen] = useState(false);
    const [alreadyOpenTimerPanel, setAlreadyOpenTimerPanel] = useState(false);

    const [friendInvited, setFriendInvited] = useState({});

    function back(){
        nav("/?username="+ tgUsernameGlob + "&id=" + telegramID);
    }
    const handleResize = () => {
        setH(window.innerHeight);
    };
    useEffect(() => {
      
      onChildChanged(OpponentEmojiRef, onEmojiChanged);
    //  checkDisconnection();
   //   onValue(connectionRef, onConnectedChange);

      checkIdsPresenceFriends(players);
    },[]);
    
    async function checkIdsPresenceFriends(ids) {
      const presenceMap = {};
      const presencePromises = ids.map(async (uid) => {
        if(uid !== auth.currentUser.uid){
          const friendRef = collection(db, "users", auth.currentUser.uid, "friends");
          const q = query(friendRef, where("uid", "==",uid));
          const querySnapshot = await getDocs(q);
          if(!querySnapshot.empty){
            presenceMap[uid] = querySnapshot.docs[0].data().username
          }
          
        }
      });
      await Promise.all(presencePromises);
      setFriendInvited(presenceMap);
    }

    const timerIntervalRef = useRef(null);
    useEffect(() => {
    /*   timerIntervalRef.current = setInterval(() => {
        setTimer(prevSeconds => prevSeconds - 1);
      }, 1000); */
  
      return () => clearInterval(timerIntervalRef.current);
    }, []);

    useEffect(() => {

      if(timer <= 20 && isTimerPanelOpen === false && alreadyOpenTimerPanel === false){
        setIsTimerPanelOpen(true);
        setAlreadyOpenTimerPanel(true);
      }
      if(timer <= 0){
        clearInterval(timerIntervalRef.current);
      
    //    setTimerDisconnect();
      }
    }, [timer]);

   /*  async function setTimerDisconnect(){
      clearInterval(timerIntervalRef.current);
      setIsTimerPanelOpen(false);
      await set(singleConnectedRef, false);
    } */
    
  /*   function checkDisconnection(){
      onDisconnect(connectionRef).update({[auth.currentUser.uid]: false});
    } */
   /*  const onConnectedChange = (snapshot) => {
      console.log("DISCONNECT");
      console.log(snapshot);
      if(snapshot.val() == null) {
        onDisconnect(connectionRef).cancel();
        off(connectionRef, "value", onConnectedChange);
        return;
      }
      //for now if one disconnect, he lose, the other has the money returned
      const connections = snapshot.val();
      console.log(connections);
      for(let [connKey, connValue] of connections){
          if(connValue === false){
            setOpponentConnected(false);
            clearInterval(timerIntervalRef.current);
          }
      }
    } */
   /*  async function postScore(s){
      const r = ref(rdb, "active_games/games_move/" + gameID + "/points/" + auth.currentUser.uid);
      await set(r,s);
    } */
     async function postFinished(){
      setGameFinisged(true);
      clearInterval(timerIntervalRef.current);
    //  const r = ref(rdb, "active_games/games_move/"+ gameID + "/finished/" + auth.currentUser.uid);
    //  await set(r,true);
    } 
     async function postTiro(){
      console.log("tiro");
      setTimer(timeTimerStart);
      setAlreadyOpenTimerPanel(false);
      const r2 = ref(rdb, "active_games/games_move/" + gameID + "/lastMoveTime/" + auth.currentUser.uid);
      await set(r2, Date.now());
    } 
    async function postWin(winner){
      //setGameFinisged(true);
      //clearInterval(timerIntervalRef.current);
      if(!urlToCallWin.includes(url)) return;
      
      if(winner !== auth.currentUser.uid) return;
      setYouWin(true);
      try {
        const funct = httpsCallable(functions,"onPvpGameWin");
        const res = await funct({gameID: gameID});
        console.log(res);
      } catch (error) {
        console.log("error win", error);
          showErrorNotification("Error win");
      }
     // const r = ref(rdb, "active_games/games_move/"+ gameID + "/winner");
    //  await set(r,winner);
    }
    function onEmojiChanged(snapshot){
      
      const uid = snapshot.key;
      const emojiDetails = snapshot.child("name").val();
      
      const iframe = document.getElementById('gameFrame');
      iframe.contentWindow.postMessage({ type: 'emoji_' + url,playerID: uid,emojiName: emojiDetails }, '*');      
     
    }
    async function postNewEmoji(name){
      const myEmojiRef = ref(rdb, "active_games/games_move/"+ gameID + "/emoji/" + auth.currentUser.uid);
      await set(myEmojiRef, {name: name, date: Date.now()});
    }
    
    useEffect(() => {
        window.addEventListener("resize", handleResize);
        const iframe = document.getElementById('gameFrame');
        iframe.contentWindow.levelToPlay = level;
        iframe.contentWindow.username = auth.currentUser.displayName;
        iframe.contentWindow.playerName = auth.currentUser.displayName;
        iframe.contentWindow.roomID = gameID;
        iframe.contentWindow.playerID = auth.currentUser.uid;
        iframe.contentWindow.numberOfPlayers = players_count;


        return () => {
          window.removeEventListener("resize", handleResize);
        };
      }, []);

    useEffect(() => {
      const riceviMessaggio = (event) => {

       /*  if (event.data.type === ('score_' + url)) {
          const numScore = event.data.numScore;
          postScore(numScore);
        } */
       console.log(event.data);
        if (event.data.type === ('finished_') + url) {
          setTimeout(()=>{
            postFinished();
          }, 2000);
          setTimeout(() => {
            setCanClose(true);

          }, 5000);
         
        }
        if (event.data.type === ('win_') + url) {
          console.log("winner: ",event.data.winner );
          if(event.data.winner === auth.currentUser.uid){
            postWin(auth.currentUser.uid);
            setCanClose(true);
          }
        }
        if (event.data.type === ('move_' + url)) {
          postTiro();
        }
      };
  
      window.addEventListener('message', riceviMessaggio);

      const onEmojiReceived = (event) => {
        if (event.data.type === 'emoji_uno') {
          const emojiPlayerId = event.data.playerID;
          const emojiName = event.data.emojiName;

          //show emoji (emojiName) to player (emojiPlayerId)

        }
      }
      window.addEventListener("message", onEmojiReceived);
  
      return () => {
        window.removeEventListener('message', riceviMessaggio);
      };
    }, []);

    function TimerPanel(){
      return(
        <div style={{position: "fixed", top: "20vh", left: "10vw", width: "80vw", height: "40vh", borderRadius: "20px", zIndex: 30, background: 'linear-gradient(to right, rgba(9, 22, 30, 0.8), rgba(0, 0, 0, 0.8))' }}>
          <RColumn height={"100%"} verticalAlignment="center" horizontalAlign="center" width={"100%"}>
            <RTitile>Are you there?</RTitile>
            <Space/>
            <RText>Make a move in {timer} seconds or you will lose!</RText>
            <Space/><Space/>
            <PrimaryBtn text={"OK"} onClick={() => setIsTimerPanelOpen(false)}/>
          </RColumn>
        </div>
      )
    }
 
 
    function OpponentDisconnectedWid(){
      return(
        <div style={{zIndex: 4, position: "fixed", top: "0px", left:"0px",width: "100%", height:h, backgroundColor: ColorPalette.bgColor}}>
          <RColumn>
          <Space/>
        <RText align={"center"} weight="bold" size="24px">Opponent disconnected</RText>       
        <Space/>
        <Space/>
        <PrimaryBtn onClick={back} text={"Close"}/>
        </RColumn>
        </div>
      )
    }
    function TimerDisconnectWid(){
      return(
        <div style={{zIndex: 4, position: "fixed", top: "0px", left:"0px",width: "100%", height:h, backgroundColor: ColorPalette.bgColor}}>
          <RColumn>
          <Space/>
        <RText align={"center"} weight="bold" size="24px">You disconnected</RText>       
        <Space/>
        <Space/>
        <RTitile color={ColorPalette.accentColor} weight="bold" size="26px">YOU Lose</RTitile>
        <Space/>
        <PrimaryBtn onClick={back} text={"Close"}/>
        </RColumn>
        </div>
      )
    }
    
    async function sendInviteReqest(uid){
      setFriendInvited((prev) => ({...prev, [uid]: true}));
      const funct = httpsCallable(functions, "addFriend");
      try {
       const res = await funct({"uid": uid});
       //console.log(res);
       if(res.data.success !== true){
           showErrorNotification(res.data.msg);
           return;
       }
       showSuccessNotification("Friend invited");
      } catch (error) {
           showErrorNotification("There was an error");
      }
    }
    function FinishPanel(){
      return(
        <div style={{zIndex: 4, position: "fixed", top: "0px", left:"0px",width: "100%", height:h, backgroundColor: ColorPalette.bgColor}}>
          <RColumn height={"100%"} verticalAlignment="space-between">
            <RColumn width={"100%"}>
            <Space/>
            <RText align={"center"} weight="bold" size="24px">Game Finished</RText>
            <Space/>
            {youWin && <>
              <RTitile>You win!</RTitile>
              <Space/>
              <RText>+ ${((price * players_count) * 0.95) - price}</RText>
              <Space/>
            </>}
            {Object.entries(friendInvited).filter((val, ind) => val[1] !== true).map(([key, value]) => (
              <div key={key} style={{backgroundColor: "#2c1e35", padding: "4px 21px", borderRadius: "10px", width: "80%", maxWidth: "450px", marginTop: "9px"}}>
                <RRow width={"100%"} horizontalAlign="space-between">
                    <RText weight="bold" size="20px">{value}</RText>
                    <SecondaryBtn text={"Add friend"} onClick={() => sendInviteReqest(key)}/>
                </RRow>
              </div>
            ))}
            <Space/>
            </RColumn>
          <RColumn>
          {gameFinished && canClose && <PrimaryBtn onClick={back} text={"Close"}/>}
          <Space height="30px"/>
          </RColumn>
        
        </RColumn>
        </div>
      )
    }
    return(
        <>
        <iframe id="gameFrame" width="100%" height={h - 15} src={urlToIndexHtml[url]} style={{border: "none"}} ></iframe>
        {isTest && <div style={{position: "fixed", zIndex: 3, bottom: "10px", left: "10px"}}>{timer}</div>}
       {isTimerPanelOpen && timer > 0 && <TimerPanel/>}
       {timer <= 0 && <TimerDisconnectWid/>}
        <EmojiPanel onSelectedEmoji={(name) => postNewEmoji(name)}/>
        {gameFinished && opponentConnected && <FinishPanel/>}
        {!opponentConnected && <OpponentDisconnectedWid/>}
       {/*  <div style={{position: "fixed", left: "10px", bottom: "10px", zIndex: 4}}>
            <dvi onClick={back}>Exit</dvi>
        </div> */}
        </>
    )
}