import { useState } from "react";
import { RColumn, RRow, RText, RTitile, Space } from "../compnents/otherCOmps";
import PointsLeaderboard from "./pointsLeaderboard";
import { ColorPalette } from "../global/const";
import { GlobalPnLPage } from "./globalPnlPage";
import { TabButton } from "../compnents/compes";
import P2ERanks from "./p2eRanks";

export default function RanksPage() {
  const [type, setType] = useState("points");

  return (
    <RColumn width={"100%"}>
      <Space />
      <RRow horizontalAlign="space-between" width={"100%"}>
        <RTitile>Ranks</RTitile>

        <RRow horizontalAlign="right" width={"100%"}>
          <TabButton
            selected={type === "points"}
            onClick={() => setType("points")}
          >
            Points
          </TabButton>
          <TabButton selected={type === "pnl"} onClick={() => setType("pnl")}>
            PnL
          </TabButton>
          <TabButton selected={type === "p2e"} onClick={() => setType("p2e")}>
            P2E
          </TabButton>
        </RRow>
      </RRow>
      <Space />
      {type === "pnl" && <GlobalPnLPage />}
      {type === "points" && <PointsLeaderboard />}
      {type === "p2e" && <P2ERanks />}
      <Space height="100px" />
    </RColumn>
  );
}
