import { useEffect, useState } from "react";
import {
  InputField,
  LoadingScreen,
  Padding,
  PrimaryBtn,
  RColumn,
  RRow,
  RText,
  RTitile,
  SecondaryBtn,
  Space,
  SpaceHorizontal,
} from "../compnents/otherCOmps";
import { ColorPalette } from "../global/const";
import copyIcon from "../assets/copy.png";
import { copyString } from "../global/util";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { auth, db, functions } from "../firebase";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../compnents/errorMessagesComp";
import { httpsCallable } from "firebase/functions";
import { format } from "date-fns";
import { greenColor, redColor } from "../profile/profilePage";
import { ContainerBorder } from "../compnents/compes";

export default function FriendsPage({ userCode, isMobile }) {
  const [isAddingFriend, setIsAddingFriend] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [invitingCode, setInvitingCode] = useState("");

  const [friendToCancel, setFriendToCancel] = useState(null);
  const [myFriends, setMyFrineds] = useState([]);

  const inviteLinkPre = "https://t.me/TheGameHubBot?start=friend=" + userCode;

  const [typeView, setTypeView] = useState(0);

  useEffect(() => {
    getFriends();
  }, []);

  async function getFriends() {
    const friendsRef = collection(db, "users", auth.currentUser.uid, "friends");
    const q = query(friendsRef, orderBy("date", "desc"), limit(30));

    const friendsDocs = await getDocs(q);
    const friendsss = [];
    friendsDocs.docs.forEach((element) => {
      friendsss.push({ ...element.data(), code: element.id });
    });
    setMyFrineds(friendsss);
  }

  async function inviteFriendWithCode(code) {
    if (code.length !== 6) {
      showErrorNotification("Code must be 6 charater");
      return;
    }
    setIsLoading(true);
    const funct = httpsCallable(functions, "addFriend");
    try {
      const res = await funct({ code: code });
      if (res.data.success !== true) {
        showErrorNotification(res.data.msg);
        setIsLoading(false);
        return;
      }
      showSuccessNotification("Friend invited");
    } catch (error) {
      showErrorNotification("There was an error");
    }
    setIsLoading(false);
    setIsAddingFriend(false);
    getFriends();
  }
  async function approveFriend(code) {
    //console.log(code);
    setIsLoading(true);
    const funct = httpsCallable(functions, "approveFriend");
    try {
      const res = await funct({ code: code });
      if (res.data.success !== true) {
        showErrorNotification(res.data.msg);
        setIsLoading(false);
        return;
      }
      showSuccessNotification("Friend accepted");
    } catch (error) {
      showErrorNotification("There was an error");
    }
    setIsLoading(false);
    setIsAddingFriend(false);
    getFriends();
  }
  async function delateFriend(code) {
    //console.log(code);
    setIsLoading(true);
    const funct = httpsCallable(functions, "delateFriend");
    try {
      const res = await funct({ code: code });
      if (res.data.success !== true) {
        showErrorNotification(res.data.msg);
        setIsLoading(false);
        return;
      }
      showSuccessNotification("Friend delated");
    } catch (error) {
      showErrorNotification("There was an error");
    }
    setIsLoading(false);
    setIsAddingFriend(false);
    getFriends();
    setFriendToCancel(null);
  }

  function FriendsWid() {
    return (
      <>
        <RRow horizontalAlign="space-between" width={"100%"}>
          <div
            onClick={() => setTypeView(0)}
            style={{
              width: null,
              border:
                typeView === 0 ? "2px solid " + ColorPalette.accentColor2 : "",
              padding: "4px 22px",
              margin: "3px 0px",
              borderRadius: "10px",
            }}
          >
            <RText
              align={"center"}
              weight="bold"
              size={isMobile ? "12px" : undefined}
            >
              My friends
            </RText>
          </div>
          <div
            onClick={() => setTypeView(1)}
            style={{
              width: null,
              border:
                typeView === 1 ? "2px solid " + ColorPalette.accentColor2 : "",
              padding: "4px 22px",
              margin: "3px 0px",
              borderRadius: "10px",
            }}
          >
            <RText
              align={"center"}
              weight="bold"
              size={isMobile ? "12px" : undefined}
            >
              Invited (
              {
                myFriends.filter(
                  (f) => f.isSended === true && f.state === "waiting"
                ).length
              }
              )
            </RText>
          </div>
          <div
            onClick={() => setTypeView(2)}
            style={{
              width: null,
              border:
                typeView === 2 ? "2px solid " + ColorPalette.accentColor2 : "",
              padding: "4px 22px",
              margin: "3px 0px",
              borderRadius: "10px",
            }}
          >
            <RText
              align={"center"}
              weight="bold"
              size={isMobile ? "12px" : undefined}
            >
              Received (
              {
                myFriends.filter(
                  (f) => f.isSended === false && f.state === "waiting"
                ).length
              }
              )
            </RText>
          </div>
        </RRow>
        {myFriends
          .filter((f) => {
            if (typeView === 0) return f.state === "confirmed";
            if (typeView === 1)
              return f.state === "waiting" && f.isSended === true;
            if (typeView === 2)
              return f.state === "waiting" && f.isSended === false;
          })
          .map((friend, ind) => (
            <SingleFriend data={friend} key={ind} />
          ))}
      </>
    );
  }

  function SingleFriend({ data }) {
    return (
      <>
        <ContainerBorder
          heightMobile=""
          heightPc=""
          margin="6px 0px"
          padding="12px 20px"
        >
          <RRow horizontalAlign="space-between" verticalAlignment="center">
            <RColumn horizontalAlign="start">
              <RText>{format(data.date, "iii d MMM")}</RText>
              <RText weight="bold" size="18px">
                {data.username}
              </RText>
            </RColumn>
            <RRow>
              {data.state === "waiting" && data.isSended === true && (
                <RText size="14px" color="orange">
                  Waiting approval
                </RText>
              )}
              {data.state === "waiting" && data.isSended === false && (
                <PrimaryBtn
                  text={"Approve"}
                  onClick={() => approveFriend(data.code)}
                />
              )}
              <SpaceHorizontal />
              <div
                onClick={() => setFriendToCancel(data.code)}
                style={{ cursor: "pointer" }}
              >
                <RText weight="bold" color={redColor}>
                  X
                </RText>
              </div>
            </RRow>
          </RRow>
        </ContainerBorder>
      </>
    );
  }

  function InviteModal() {
    return (
      <div
        style={{
          position: "fixed",
          height: "100vh",
          width: "100%",
          backgroundColor: "rgba(0,0,0,0.5",
        }}
      >
        <RColumn verticalAlignment="center" bgColor={"red"} height={"100%"}>
          <div
            style={{
              height: "300px",
              width: "90%",
              maxWidth: "500px",
              backgroundColor: ColorPalette.bgColor,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div
              onClick={() => copyString(inviteLinkPre, "Invite link copied")}
            >
              <RRow>
                <img src={copyIcon} width={"14px"} />
                <SpaceHorizontal />
                <RText>{inviteLinkPre}</RText>
              </RRow>
            </div>
          </div>
        </RColumn>
      </div>
    );
  }
  function DelateFriendModal() {
    return (
      <div
        style={{
          zIndex: 10,
          position: "absolute",
          height: "100vh",
          width: "100%",
          backgroundColor: "rgba(0,0,0,0.5",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            height: "180px",
            width: "90%",
            maxWidth: "300px",
            backgroundColor: ColorPalette.bgColor,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            borderRadius: "12px",
          }}
        >
          <RText>Remove friend?</RText>
          <Space />
          <RRow width={"100%"} horizontalAlign="space-evenly">
            <div
              onClick={() => setFriendToCancel(null)}
              style={{ cursor: "pointer" }}
            >
              <RText>Cancel</RText>
            </div>
            <SecondaryBtn
              text={"Remove"}
              onClick={() => delateFriend(friendToCancel)}
            />
          </RRow>
        </div>
      </div>
    );
  }
  return (
    <div style={{ position: "relative", height: "100vh", width: "100%" }}>
      {friendToCancel && <DelateFriendModal />}
      {isAddingFriend && (
        <>
          <div
            style={{
              position: "absolute",
              height: "100vh",
              width: "100%",
              backgroundColor: "rgba(0,0,0,0.5",
              zIndex: 10,
            }}
          >
            <div
              style={{
                height: "85%",
                width: "100%",
                backgroundColor: ColorPalette.bgColor,
                bottom: "0px",
                position: "absolute",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <RColumn width={"95%"}>
                <div
                  onClick={() => setIsAddingFriend(false)}
                  style={{
                    position: "absolute",
                    right: "12px",
                    top: "12px",
                    cursor: "pointer",
                  }}
                >
                  <RTitile>X</RTitile>
                </div>
                <Space />
                <RRow>
                  <RText weight="bold">Add Friend</RText>
                </RRow>
                <Space />
                <RRow width={"100%"} horizontalAlign="space-between">
                  <InputField
                    width="55%"
                    placeHolder={"Friend code"}
                    maxChar={6}
                    value={invitingCode}
                    setValue={(v) =>
                      setInvitingCode(v.target.value.toUpperCase())
                    }
                  />
                  <SpaceHorizontal />
                  <SecondaryBtn
                    onClick={() => inviteFriendWithCode(invitingCode)}
                    text={"Send request"}
                  />
                </RRow>
              </RColumn>
            </div>
          </div>
        </>
      )}
      {isLoading && <LoadingScreen />}
      {/*  <InviteModal/> */}
      <RColumn width={"100%"} horizontalAlign="center">
        <RColumn width={"96%"}>
          <Space />
          <RRow horizontalAlign="space-between" width={"100%"}>
            <div onClick={() => copyString(userCode, "Code copied")}>
              <RRow>
                <img src={copyIcon} width={"14px"} />
                <SpaceHorizontal />
                <RText>Your code: {userCode}</RText>
              </RRow>
            </div>
            <SecondaryBtn
              text={"Add friends"}
              onClick={() => setIsAddingFriend(true)}
            />
          </RRow>
          <Space />
          <FriendsWid />
          <Space />
        </RColumn>
      </RColumn>
    </div>
  );
}
