import { useEffect, useState } from "react";
import "./App.css";
import { HomePage } from "./home/homePage";
import { auth, db, logOut } from "./firebase";
import {
  BrowserRouter,
  Routes,
  Route,
  MemoryRouter,
  useNavigate,
  useLocation,
} from "react-router-dom";

import "react-toastify/dist/ReactToastify.css";
import ProfilePage from "./profile/profilePage";
import LobbyPage from "./lobby/lobbyPage";
import CheckersGames from "./checkers/checkersGame";
import {
  RColumn,
  RRow,
  RText,
  RTitile,
  SecondaryBtn,
  Space,
  SpaceHorizontal,
} from "./compnents/otherCOmps";
import logoImg from "./assets/logo.png";
import ScoreGame from "./pvpgames/scoresGames";
import RouletteGame from "./roulette/roulette";
import P2eGame from "./p2eGames/p2eGames";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import TabController from "./home/tabController";
import RacingGame from "./pvpgames/racingGame";
import {
  showErrorNotification,
  showSuccessNotification,
} from "./compnents/errorMessagesComp";
import F2PGames from "./f2pGames/f2pGames";
import {
  BaseInfo,
  StatusInfo,
  TaskDoc,
  isTest,
  versionGame,
} from "./global/const";
import NoActivePage from "./home/noActivePage";
import PvpMovesGames from "./pvpgames/pvpMovesGame";
import ScoreGameRealTime from "./pvpgames/scoreRealtimeGames";
import EvenBetPage from "./evenbet/evenbetPage";
import QTCasinoPage from "./qt-casino/qtCasinoPage";
import { httpsCallable } from "firebase/functions";
import { useGameType } from "./theme";
import StartBanners from "./startbanners/startBanner";
import LoadingIcons from "react-loading-icons";
import { signInWithCustomToken } from "firebase/auth";
import axios from "axios";
import { copyString } from "./global/util";
import { ToastContainer } from "react-toastify";
import { RiFileCopy2Line, RiFileCopyLine } from "@remixicon/react";

export var tgUsernameGlob = "";
export var telegramID;

export default function Starting() {
  const [tgUsername, setTgUsername] = useState(null);
  const location = useLocation();
  const nav = useNavigate();
  const [noActiveMsg, setNoActiveMsg] = useState("");
  const { gameType, setGameType, isTestInProd } = useGameType();

  const [state, setState] = useState("loading");
  console.log(isTestInProd);
  useEffect(() => {
    if (location.pathname !== "/") {
      nav("/");
    }

    const unsubscribe = auth.onAuthStateChanged(async (authUser) => {
      setState("loading");
      const statusD = await getDoc(doc(db, "aBase/status"));
      StatusInfo.data = statusD.data();
      if (
        isTest === false &&
        !isTestInProd &&
        StatusInfo.data.active === false
      ) {
        setState("no_active");
        setNoActiveMsg(StatusInfo.data.no_active_msg);
        return;
      }

      if (authUser) {
        tgUsernameGlob = authUser.displayName ?? "";
        if (!authUser.displayName || authUser.displayName == "undefined") {
          {
            setState("no_username");
            return;
          }
        }
        setTgUsername(tgUsernameGlob);
        //console.log(tgUsernameGlob);
        if (isTest || isTestInProd) {
          //check if tester
          const testDoc = await getDoc(doc(db, "tester/" + authUser.uid));
          if (!testDoc.exists()) {
            setState("no_tester");
            return;
          }
        }
        await loadData(authUser.uid);
        setState("logged");
        const queryParams = new URLSearchParams(window.location.search);
        const pageParam = queryParams.get("page");
        if (pageParam === "task") setGameType("games");
        if (pageParam === "wallet") setGameType("games");
        if (pageParam === "referral") setGameType("games");
        if (pageParam === "challenge") setGameType("games");
      } else {
        setState("loading");
        //get if from mini app
        const queryParams = new URLSearchParams(window.location.search);

        let token;

        if (queryParams.get("from") === "miniapp") {
          const initData = window.Telegram.WebApp.initData;
          try {
            const refCode = queryParams.get("refCode");

            const response = await axios.post(
              "https://us-central1-html5-gaming-bot.cloudfunctions.net/getTokenAuthFromMiniApp",
              { initData, from: "test", refCode }
            );

            if (response.data.valid) {
              console.log("Dati validi:", response.data);
              token = response.data.token;
            } else {
              setState("error_token");
              if (response.data.tgMsg) {
                showErrorNotification(response.data.tgMsg);
              } else {
                showErrorNotification("Data not successfully verified");
              }

              return;
            }
          } catch (error) {
            setState("error_token");
            showErrorNotification("Data not successfully verified");
            return;
          }
        } else {
          token = urlObject.searchParams.get("token");
          if (!token) {
            setState("no_token");
            return;
          }
        }

        try {
          await signInWithCustomToken(auth, token);
        } catch (e) {
          setState("error_token");
          showErrorNotification("Invalid token");
          return;
        }
      }
    });

    const url = window.location.href;
    const urlObject = new URL(url);
    const rid = urlObject.searchParams.get("roomID");
    if (rid && rid.length === 6) {
      setGameType("games");
      goToRoomInvited(urlObject.searchParams.get("roomID"));
    }

    return () => {
      unsubscribe();
    };
  }, [isTestInProd]);

  async function loadData(uid) {
    //get base info
    const statusD = await getDoc(doc(db, "aBase/status"));
    StatusInfo.data = statusD.data();
    if (isTest === false && !isTestInProd && StatusInfo.data.active === false) {
      setState("no_active");
      setNoActiveMsg(StatusInfo.data.no_active_msg);
      return;
    }

    const taskD = await getDoc(doc(db, "aBase/task"));
    TaskDoc.tasks = taskD.data().tasks.sort((a, b) => a.index - b.index);
    TaskDoc.points = taskD.data().points;
    const baseInfoD = await getDoc(doc(db, "aBase/baseInfo"));
    BaseInfo.baseInfoDoc = baseInfoD.data();
    BaseInfo.gamesNow = baseInfoD.data().gamesNow;
    BaseInfo.gamesName = baseInfoD.data().gamesName;
    BaseInfo.chainTokensData = baseInfoD.data().chainTokensData;
    BaseInfo.gamesMaxPlayers = baseInfoD.data().gamesMaxPlayers;
    BaseInfo.gamesPvpUrlType = baseInfoD.data().gamesPvpUrlType;
    BaseInfo.levelsThreshold = baseInfoD.data().levelsThreshold;
    BaseInfo.p2eGames = baseInfoD.data().p2eGames;
    //check if is waiting room
    const tenMinutesAgoTimestamp = Date.now() - 15 * 60 * 1000;
    const gamesRef = collection(db, "games");
    const q = query(
      gamesRef,
      where("players", "array-contains", {
        uid: auth.currentUser.uid,
        username: auth.currentUser.displayName,
      }),
      where("creationDate", ">=", tenMinutesAgoTimestamp),
      limit(1),
      where("isDiffert", "==", false)
    );
    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      const gameDoc = querySnapshot.docs[0];
      //console.log(gameDoc.data());
      nav("/lobby", {
        state: {
          gameUrl: gameDoc.data().gameUrl,
          alreadyInGameWaitingData: {
            roomID: gameDoc.id,
            code: gameDoc.data().code,
            level: gameDoc.data().level ?? false,
            isOpen: gameDoc.data().isOpen,
            player1: gameDoc.data().player1,
          },
        },
      });
    }
  }

  async function goToRoomInvited(code) {
    nav("/lobby", {
      state: {
        invitedToGame: {
          code: code,
        },
      },
    });
  }

  if (state === "no_token" || state === "error_token") {
    return (
      <RColumn width={"100%"} height={"100vh"} verticalAlignment="center">
        <img src={logoImg} width={"90px"} />
        <Space />
        <RTitile align={"center"} color="red">
          {state === "no_token"
            ? "No token auth"
            : "Invalid token auth, exit and reopen the telegram link"}
        </RTitile>
        <Space />
        <RText size="12px">v {versionGame}</RText>
      </RColumn>
    );
  }
  if (state === "loading") {
    return (
      <RColumn width={"100%"} height={"100vh"} verticalAlignment="center">
        <img src={logoImg} width={"90px"} />
        <Space />
        <LoadingIcons.ThreeDots width={"26px"} />
        <RText size="12px">v {versionGame}</RText>
      </RColumn>
    );
  }
  if (state === "no_active") {
    return <NoActivePage msg={noActiveMsg} />;
  }

  if (state === "no_username") {
    return (
      <RColumn
        horizontalAlign="center"
        verticalAlignment="center"
        height={"90vh"}
        width={"100vw"}
      >
        <RColumn width={"92%"} bgColor={"red"}>
          <Space />
          <Space />
          <Space />
          <img src={logoImg} width={"100px"} />
          <Space />
          <RTitile>No Username</RTitile>
          <Space />
          <RText align={"center"}>
            You need a Telegram username to play, set it in the Telegram
            settings and come back!
          </RText>
        </RColumn>
      </RColumn>
    );
  }

  if (state === "no_tester") {
    return (
      <>
        <ToastContainer />
        <RColumn
          horizontalAlign="center"
          verticalAlignment="center"
          height={"90vh"}
          width={"100vw"}
        >
          <RColumn width={"92%"} bgColor={"red"}>
            <Space />
            <Space />
            <Space />
            <img src={logoImg} width={"100px"} />
            <Space />
            <RTitile>You are not a tester</RTitile>
            <Space />
            <RText align={"center"}>
              Contact the admins to add you as tester, your id is
            </RText>
            <Space />
            <div onClick={() => copyString(auth.currentUser.uid, "id copied")}>
              <RRow>
                <RiFileCopyLine color="white" widths={13} />
                <SpaceHorizontal />
                <RText>{auth.currentUser.uid}</RText>
              </RRow>
            </div>
            <Space />
            <SecondaryBtn onClick={logOut} text={"Logout"} />
          </RColumn>
        </RColumn>
      </>
    );
  }

  if (state === "logged") {
    if (gameType === "init") {
      return <StartBanners />;
    } else if (gameType === "games") {
      return (
        <>
          {/* <button onClick={logOut}>o</button> */}
          <Routes>
            <Route
              path="/"
              element={<TabController tgUsername={tgUsername} />}
            />
            <Route exact path="/lobby" element={<LobbyPage />} />
            <Route exact path="/checkers" element={<CheckersGames />} />
            <Route exact path="/race" element={<RacingGame />} />
            <Route exact path="/p2e" element={<P2eGame />} />
            <Route exact path="/free" element={<F2PGames />} />
            <Route exact path="/scoreGame" element={<ScoreGame />} />
            <Route exact path="/pvpMoveGame" element={<PvpMovesGames />} />
            <Route
              exact
              path="/scoreRealTime"
              element={<ScoreGameRealTime />}
            />
            <Route exact path="/roulette" element={<RouletteGame />} />
            <Route exact path="/poker" element={<EvenBetPage />} />
            <Route exact path="/casino" element={<QTCasinoPage />} />
            <Route
              exact
              path="/profile"
              element={<ProfilePage tgUsername={tgUsername} />}
            />
          </Routes>
        </>
      );
    } else if (gameType === "casino") {
      return <QTCasinoPage />;
    } else if (gameType === "poker") {
      return <EvenBetPage />;
    }
  }
}
