import { httpsCallable } from "firebase/functions";
import { useEffect, useState } from "react";
import { auth, functions } from "../firebase";
import {
  RColumn,
  RRow,
  RText,
  RTitile,
  SecondaryBtn,
  Space,
  SpaceHorizontal,
} from "../compnents/otherCOmps";
import SpinningCircles from "react-loading-icons/dist/esm/components/spinning-circles";
import { ColorPalette } from "../global/const";
import { useGameType } from "../theme";
import { MaxWidthContainer } from "../compnents/compes";
import WalletPage from "../wallet/walletPgae";

export default function EvenBetPage() {
  const [isLoading, setIsLoading] = useState(true);

  const [h, setH] = useState(window.innerHeight - 80);

  const [evenBetUrl, setEvenBetUrl] = useState(null);
  const { gameType, setGameType } = useGameType();

  const [isWalletOpen, setIsWalletOpen] = useState(false);

  useEffect(() => {
    getAccordionDetailsUtilityClass();
  }, []);

  async function getAccordionDetailsUtilityClass() {
    console.log("start");
    const funct = httpsCallable(functions, "requestLobbyEvenBet");

    try {
      const res = await funct({});
      console.log(res.data);
      setEvenBetUrl(res.data["link"]);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const handleResize = () => {
    setH(window.innerHeight - 80);
  };
  if (isLoading) {
    return (
      <RColumn
        verticalAlignment="center"
        horizontalAlign="center"
        height={"99vh"}
      >
        <img src="gameIcons/poker.png" width={"180px"} />
        <RTitile>Loading poker lobby</RTitile>
        <Space />
        <SpinningCircles />
        <Space height="80px" />
      </RColumn>
    );
  }
  return (
    <>
      {isWalletOpen && (
        <div
          style={{
            position: "fixed",
            width: "50%",
            zIndex: 12,
            backgroundColor: ColorPalette.bgColor,
            height: h,
            bottom: 0,
            right: 0,
          }}
        >
          <div
            style={{
              position: "relative",
              left: 12,
              top: 12,
              cursor: "pointer",
            }}
            onClick={() => setIsWalletOpen(false)}
          >
            <RTitile>X</RTitile>
          </div>
          <MaxWidthContainer>
            <WalletPage />
          </MaxWidthContainer>
        </div>
      )}
      <div
        style={{
          background: ColorPalette.bgGradient,
          height: "80px",
          width: "98%",
        }}
      >
        <RRow
          verticalAlignment="center"
          height={"100%"}
          horizontalAlign="space-between"
        >
          <img
            src={auth.currentUser.photoURL}
            width={"50px"}
            style={{ borderRadius: "100%", marginLeft: "12px" }}
          />
          <RRow>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => setGameType("init")}
            >
              <RText>Home</RText>
            </div>
            <SpaceHorizontal />
            <SecondaryBtn
              onClick={() => setIsWalletOpen((p) => !p)}
              text={"Wallet"}
            />
          </RRow>
        </RRow>
      </div>
      <iframe
        src={evenBetUrl}
        style={{ border: "none" }}
        width={"100%"}
        height={h}
      />
    </>
  );
}
