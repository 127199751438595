import { useEffect, useState } from "react";
import {
  HorizontalScrollContainer,
  HorizontalScrollElement,
  TabButton,
} from "../compnents/compes";
import { RRow, Space } from "../compnents/otherCOmps";
import { BaseInfo } from "../global/const";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase";
import { Leaderboard2 } from "../p2eGames/lead2";

export default function P2ERanks() {
  const [selectedGame, setSelectedGame] = useState(
    BaseInfo.p2eGames.games_old[0]?.url
  );

  const [cacheLead, setCacheLead] = useState({});
  const [leadeData, setLeadData] = useState([]);

  useEffect(() => {
    if (cacheLead[selectedGame]) {
      setLeadData(cacheLead[selectedGame]);
      return;
    }

    const leadRef = doc(db, `leaderboards/${selectedGame}`);
    getDoc(leadRef).then((querySnapshot) => {
      const leaderboardData = Object.entries(querySnapshot.data()?.top50 ?? {})
        .sort(([, a], [, b]) => {
          if (b.score !== a.score) {
            return b.score - a.score;
          }
          return a.date - b.date;
        })
        .slice(0, 15)
        .map(([id, player], index) => ({ id, ...player, rank: index + 1 }));
      setLeadData(leaderboardData);
      setCacheLead((p) => ({ ...p, [selectedGame]: leaderboardData }));
    });
  }, [selectedGame]);
  return (
    <>
      <HorizontalScrollContainer>
        {BaseInfo.p2eGames.games_old.map((g) => (
          <HorizontalScrollElement key={g.url}>
            <TabButton
              onClick={() => setSelectedGame(g.url)}
              selected={selectedGame === g.url}
            >
              {BaseInfo.gamesName[g.url]}
            </TabButton>
          </HorizontalScrollElement>
        ))}
      </HorizontalScrollContainer>
      <Space />
      <Leaderboard2
        gameName={BaseInfo.gamesName[selectedGame]}
        players={leadeData}
      />
    </>
  );
}
