import React from 'react';
import {returnPlayerName} from './utils.js';
import {ReactCheckers} from './ReactCheckers.js';
import Board from './Board.js';
import { useNavigate } from 'react-router-dom'
import {Opponent} from './Opponent.js';
import "./style.css";
import {ref, remove, set, onValue, update, onDisconnect} from "firebase/database";

import {auth, rdb} from "../firebase.js"
import { PrimaryBtn, RColumn, RRow, RText, RTitile, Space } from '../compnents/otherCOmps.js';
import { telegramID, tgUsernameGlob } from '../aStartingPage.js';

export class Game extends React.Component {
  
    constructor(props) {
      
        //console.log(props);
        super(props);
        this.columns = this.setColumns();
        this.asc = null;
        this.ReactCheckers = new ReactCheckers(this.columns);
        this.Opponent = new Opponent(this.columns);
        this.timerInterval = null;
        this.state = {
            players: 1,
            history: {
                boardState: this.createBoard(),
                currentPlayer: props.isHome,
            },
            activePiece: null,
            moves: [],
            jumpKills: null,
            hasJumped: null,
          //  stepNumber: 0,
            winner: null,
           /*  timer: 30,
            isTimerPanelOpen: false,
            alreadyOpenTimerPanel: false */
        }
        
        this.ascoltaBoard();
        this.checkDisconnect();
    }
   /*  componentDidMount(){
        this.timerInterval = setInterval(() => {
            this.setState(prevState => ({
                ...prevState,
                timer: prevState.timer - 1
              }));

          }, 1000);
      
    } */

    /* componentWillUnmount(){
        clearInterval(this.timerInterval);
    } */
   /*  componentDidUpdate(prevProps, prevState) {
        if (prevState.timer !== this.state.timer) {
          this.checkTimer();
        }
      }
      checkTimer() {
        const { timer, isTimerPanelOpen, alreadyOpenTimerPanel } = this.state;
    
        if (timer <= 20 && !isTimerPanelOpen && !alreadyOpenTimerPanel) {
          this.setState(prev => ({
            ...prev,
            isTimerPanelOpen: true,
            alreadyOpenTimerPanel: true
          }));
        }
        if (timer <= 0) {
          clearInterval(this.timerInterval);
          //console.log("lose");
          // Aggiungi qui la logica per impostare il timer come scaduto
          // ad esempio: this.setTimerDisconnect();
        }
      }
    async setTimerDisconnect(){
        clearInterval(this.timerInterval);
        this.setState(prev => ({
            ...prev,
            isTimerPanelOpen: false,
        }));
        const singleConnectedRef = ref(rdb, "active_games/checkers/" + this.props.id + "/connected/" + auth.currentUser.uid);
         await set(singleConnectedRef, false);
        } */
async checkDisconnect(){
    const r = ref(rdb, "active_games/checkers/" + this.props.id + "/connected");
    onDisconnect(r).update({[auth.currentUser.uid]: false});

}
async ascoltaBoard(){
        const r = ref(rdb, "active_games/checkers/" + this.props.id);
        if(this.props.isHome){
            await set(r, {history: this.state.history, 
                    winner: "null",
                    player1: this.props.p1UID,
                    player2: this.props.p2UID,
                    connected: {
                        [this.props.p1UID]: true,
                        [this.props.p2UID]: true,
                    }
            });
        }
       this.asc = onValue(r, (snap) => {
            if(!snap.exists){
                this.asc();
                return;
            }
            const oppUid = !this.props.isHome ? this.props.p1UID : this.props.p2UID;
            if(!snap.val()) {return;}
            
            //console.log(snap.val());
            let nb = snap.val()["history"];
            let w =  snap.val()["winner"];

            if(snap.val()["connected"][oppUid] === false){
                w = "oppDisc";
            }
            for(const key in nb.boardState){
                if(nb.boardState[key] === "null"){
                    nb.boardState[key] = null;
                }
            }
            if(w === "null"){
                w = null;
            }
            this.setState({history: nb});
            this.setState({winner: w});
            if(w !== null){
                const r = ref(rdb, "active_games/checkers/" + this.props.id + "/connected");
                onDisconnect(r).cancel();
            }
        });
}
async updateBoardOnline(board, pl, w){
        const r = ref(rdb, "active_games/checkers/" + this.props.id);
        const nb = {
            boardState: board,
            currentPlayer: pl,
        }
        for(const key in nb.boardState){
            if(nb.boardState[key] === null){
                nb.boardState[key] = "null";
            }
        }
        if(w === null) w = "null";
        await update(r, {history: nb, winner:w });
}
 closeGame = async () => {
    const r = ref(rdb, "active_games/checkers/" + this.props.id + "/connected");
    const navigate = this.props.nav;
    onDisconnect(r).cancel();
    this.asc();
    navigate("/?username="+ tgUsernameGlob + "&id=" + telegramID);
}

    setColumns() {
        const columns = {};
        columns.a = 0;
        columns.b = 1;
        columns.c = 2;
        columns.d = 3;
        columns.e = 4;
        columns.f = 5;
        columns.g = 6;
        columns.h = 7;

        return columns;
    }

    createBoard() {

        let board = {};

        for (let key in this.columns) {

            if (this.columns.hasOwnProperty(key)) {
                for (let n = 1; n <= 8 ; ++n) {

                    let row = key + n;
                    board[row] = "null";

                }
            }
        }

        board = this.initPlayers(board);
        
        return board;
    }

    initPlayers(board) {
        const player1 = ['a8', 'c8', 'e8', 'g8', 'b7', 'd7', 'f7', 'h7', 'a6', 'c6', 'e6', 'g6',];
        const player2 = ['b3', 'd3', 'f3', 'h3', 'a2', 'c2', 'e2', 'g2', 'b1', 'd1', 'f1', 'h1',];

        let self = this;

        player1.forEach(function (i) {
            board[i] = self.createPiece(i, 'player1');
        });

        player2.forEach(function (i) {
            board[i] = self.createPiece(i, 'player2');
        });

        return board;
    }

    createPiece(location, player) {
        let piece = {};

        piece.player   = player;
        piece.location = location;
        piece.isKing   = false;

        return piece;
    }

    getCurrentState() {
        return this.state.history;
       
    }
 
  
    handleClick(coordinates) {
      
    ////console.log(this.state.history.currentPlayer);
        if (this.state.winner !== null) {
            return;
        }

        const currentState = this.getCurrentState();
        const boardState = currentState.boardState;
        const clickedSquare = boardState[coordinates];
       
        // Clicked on a piece
        if (clickedSquare !== null) {
         //   //console.log(clickedSquare.player);
            // Can't select opponents pieces
         //   //console.log(this.state.history);
            if(this.state.history.currentPlayer !== this.props.isHome){
               
                return;
            }
            if (clickedSquare.player !==  returnPlayerName(currentState.currentPlayer)) {
                return;
            }
           
            // Unset active piece if it's clicked
            if (this.state.activePiece === coordinates && this.state.hasJumped === null) {
                //console.log("g");
                this.setState({
                    activePiece: null,
                    moves: [],
                    jumpKills: null,
                });
                return;
            }

            // Can't choose a new piece if player has already jumped.
            if (this.state.hasJumped !== null && boardState[coordinates] !== null) {
                return;
            }

            // Set active piece
            let movesData = this.ReactCheckers.getMoves(boardState, coordinates, clickedSquare.isKing, false);

            this.setState({
                activePiece: coordinates,
                moves: movesData[0],
                jumpKills: movesData[1],
            });

            return;
        }

        // Clicked on an empty square
        if (this.state.activePiece === null) {
            return;
        }

        // Moving a piece
        if (this.state.moves.length > 0) {
            const postMoveState = this.ReactCheckers.movePiece(coordinates, this.state);

            if (postMoveState === null) {
                return;
            }

            this.updateStatePostMove(postMoveState);

            // Start computer move is the player is finished
          /*   if (postMoveState.currentPlayer === false && postMoveState.winner === null) {
                this.computerTurn();
            } */
        }
    }
  

    computerTurn(piece = null) {
        if (this.state.players > 1) {
            return;
        }

        setTimeout(()=> {
            const currentState = this.getCurrentState();
            const boardState = currentState.boardState;

            let computerMove;
            let coordinates;
            let moveTo;

            // If var piece != null, the piece has previously jumped.
            if (piece === null) {
                //computerMove = this.Opponent.getRandomMove(boardState, 'player2');
                computerMove = this.Opponent.getSmartMove(this.state, boardState, 'player2');
                
                coordinates = computerMove.piece;
                moveTo = computerMove.moveTo;
            } else {
                // Prevent the computer player from choosing another piece to move. It must move the active piece
                computerMove = this.ReactCheckers.getMoves(boardState, piece, boardState[piece].isKing, true);
                coordinates = piece;
                moveTo = computerMove[0][Math.floor(Math.random()*computerMove[0].length)];
            }

            const clickedSquare = boardState[coordinates];

            let movesData = this.ReactCheckers.getMoves(boardState, coordinates, clickedSquare.isKing, false);

            this.setState({
                activePiece: coordinates,
                moves: movesData[0],
                jumpKills: movesData[1],
            });

            setTimeout(()=> {
                const postMoveState = this.ReactCheckers.movePiece(moveTo, this.state);

                if (postMoveState === null) {
                    return;
                }

                this.updateStatePostMove(postMoveState);

                // If the computer player has jumped and is still moving, continue jump with active piece
                if (postMoveState.currentPlayer === false) {
                    this.computerTurn(postMoveState.activePiece);
                }
            },
            1000);
        },
        3000);
    }
/* async postTimeMove(){
    const r2 = ref(rdb, "active_games/games_score/" + this.props.id + "/lastMoveTime/" + auth.currentUser.uid);
    await set(r2, Date.now());
} */
    updateStatePostMove(postMoveState) {
        this.setState(prev => ({
            ...prev,
            history:{
                boardState: postMoveState.boardState,
                currentPlayer: postMoveState.currentPlayer,
            },
            activePiece: postMoveState.activePiece,
            moves: postMoveState.moves,
            jumpKills: postMoveState.jumpKills,
            hasJumped: postMoveState.hasJumped,
           // stepNumber: this.state.history.length,
            winner: postMoveState.winner,
           /*  timer: 30,
            alreadyOpenTimerPanel: false, */
        }));
        //this.postTimeMove();
        this.updateBoardOnline(postMoveState.boardState, postMoveState.currentPlayer,postMoveState.winner);
    }

   /*  undo() {
        const backStep = parseInt(this.state.stepNumber, 10) -1;
        if (backStep < 0) {
            return;
        }
        const unsetHistory = this.state.history.slice(0, backStep+1);
        this.setState({
            history: unsetHistory,
            activePiece: null,
            moves: [],
            jumpKills: null,
            hasJumped: null,
            stepNumber: backStep,
            winner: null,
        });
    } */

    setPlayers(players) {
        this.setState({
            players: players,
        })
    }

    render() {
        const columns = this.columns;
        const stateHistory = this.state.history;
        const activePiece = this.state.activePiece;
        const currentState = stateHistory;
        const boardState = currentState.boardState;
        const currentPlayer = currentState.currentPlayer;
        const moves = this.state.moves;

        let gameStatus;


        switch (this.state.winner) {
            case 'player1pieces':
                gameStatus = (!this.props.isHome ? this.props.p1 ?? "Opponent" : "YOU") + " win";
                break;
            case 'player2pieces':
                gameStatus = (this.props.isHome ? this.props.p2 ?? "Opponent" : "YOU") + " win";
                break;
            case 'player1moves':
                gameStatus = 'No moves left ' + (!this.props.isHome ? this.props.p1 ?? "Opponent" : "YOU") + " win";
                break;
            case 'player2moves':
                gameStatus = 'No moves left '+ (this.props.isHome ? this.props.p2 ?? "Opponent" : "YOU") + " win";
                break;
            case 'oppDisc':
                gameStatus = "Opponent left YOU win";
                break;
            default:
                gameStatus = this.state.history.currentPlayer === this.props.isHome ? 'You' : (!this.props.isHome ? this.props.p1 ?? "Opponent" : this.props.p2 ?? "Opponent");
                break;
        }

   /*      if (this.state.players === null) {
            return(
                <Router history={browserHistory} basename={'react-checkers'} >
                    <div className="players-select">
                        <div className="players">
                            <div className="one-player" onClick={()=> this.setPlayers(1) }>One Player</div>
                        </div>
                        <div className="players">
                            <div className="two-player" onClick={()=> this.setPlayers(2) }>Two Player</div>
                        </div>
                    </div>
                </Router>
            )
        } */

        let rotation = "rotate(0deg)";
        if(this.props.isHome) rotation = "rotate(180deg)";
        return(
            <>
           {/*  {this.state.isTimerPanelOpen && this.state.timer > 0 &&  <div style={{position: "fixed", top: "20vh", left: "25vw", width: "50vw", height: "40vh", borderRadius: "20px", zIndex: 30, background: 'linear-gradient(to right, rgba(9, 22, 30, 0.8), rgba(0, 0, 0, 0.8))' }}>
          <RColumn height={"100%"} verticalAlignment="center" horizontalAlign="center" width={"100%"}>
            <RTitile>Are you there?</RTitile>
            <Space/>
            <RText>Make a move in {this.state.timer} seconds or you will lose!</RText>
            <Space/><Space/>
            <PrimaryBtn text={"OK"} onClick={() => this.setState(prev => ({...prev, isTimerPanelOpen: false}))}/>
          </RColumn>
        </div>} */}
                <div className="reactCheckers">
                    <div className="game-status">
                        {gameStatus}
                    </div>
                    <div className="game-board" style={{transform: rotation}}>
                        <Board
                            boardState = {boardState}
                            currentPlayer = {currentPlayer}
                            activePiece = {activePiece}
                            moves = {moves}
                            columns = {columns}
                            onClick = {(coordinates) => this.handleClick(coordinates)}
                        />
                    </div>
                  
                  {/*   <div className="time-travel">
                        <button className={undoClass} onClick={()=>this.undo()}>Undo</button>
                    </div> */}
                </div>
                {this.state.winner !== null &&
                <div>
                <Space/>
                <RRow horizontalAlign='center'>
                    <PrimaryBtn text={"Close"} _width={"150px"} onClick={this.closeGame}/>
                </RRow>
                </div>
    }
                 </>
        );
    }
}