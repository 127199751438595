import { useEffect, useRef, useState } from "react";
import {
  BackToHomeBtn,
  InputField,
  LoadingScreen,
  Padding,
  PrimaryBtn,
  RColumn,
  RRow,
  RText,
  RTitile,
  SecondaryBtn,
  Space,
  SpaceHorizontal,
  TextButton,
  generateAlphaNumericCode,
} from "../compnents/otherCOmps";
import {
  Timestamp,
  addDoc,
  arrayRemove,
  collection,
  deleteDoc,
  deleteField,
  doc,
  getDocs,
  limit,
  onSnapshot,
  query,
  runTransaction,
  updateDoc,
  where,
} from "firebase/firestore";
import { auth, db, functions } from "../firebase";
import reloadImg from "../assets/reload.png";
import { telegramID, tgUsernameGlob } from "../aStartingPage";
import copyIcon from "../assets/copy.png";
import { Howl } from "howler";
import { useLocation, useNavigate } from "react-router-dom";
import {
  BaseInfo,
  ColorPalette,
  getUserBalances,
  isTest,
} from "../global/const";
import { showErrorNotification } from "../compnents/errorMessagesComp";
import { ToastContainer } from "react-toastify";
//import { getRealBalance } from "../global/util";
import { httpsCallable } from "firebase/functions";
import { copyString, formatNumber, formatTimeAgoMinutes } from "../global/util";
import { getPriceToken } from "../global/apis";
import { analytics } from "../firebase";
import { logEvent } from "firebase/analytics";
import Slider from "@mui/material/Slider";

import notificaSOund from "../audioFiles/notification.wav";
import { ContainerBorder } from "../compnents/compes";
import { useGameType } from "../theme";
import { RiRestartLine } from "@remixicon/react";
const LobbyPage = () => {
  const [listRooms, setListRooms] = useState([]);

  const [listGameInProcess, setListGamesInProcess] = useState([]);

  let pricesMarks = [
    { scaledValue: 5, value: 2 },
    { scaledValue: 10, value: 3 },
    { scaledValue: 25, value: 4 },
    { scaledValue: 50, value: 5 },
    { scaledValue: 100, value: 6 },
    { scaledValue: 200, value: 7 },
    { scaledValue: 300, value: 8 },
    { scaledValue: 500, value: 9 },
    { scaledValue: 1000, value: 10 },
  ];

  if (isTest) {
    pricesMarks.splice(0, 0, { scaledValue: 0, value: 1 });
  }

  const numLevels = {
    golf: 16,
  };

  const nav = useNavigate();
  const location = useLocation();
  const gameUrl = location.state.gameUrl;

  const alreadyInGameWaitingData = location.state.alreadyInGameWaitingData;
  const invitedToGame = location.state.invitedToGame;

  const [isWaiting, setIsWaiting] = useState();
  const [partID, setPartID] = useState();

  const [isCreateRoom, setIsCreateRoom] = useState(false);
  const [selectedRoomPrice, setSelectedRoomPrice] = useState();
  const [selectedIsOpen, setSelectedIsOpen] = useState(true);
  const { userBalances } = useGameType();

  const [insertedGameCode, setInsertedGameCode] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const [isSelectingLevels, setIsSelectingLevel] = useState(true);
  const [selectedLevl, setSelectedLevl] = useState(false);

  const [gameDocData, setGameDocData] = useState();

  const [currentTokenPrice, setCurrentTokenPrice] = useState(0);

  const [isSelectingTokenJoinRoom, setIsSelectingTokenJoinRoom] =
    useState(false);
  const [selectedRoomToJoin, setSelectedRoomToJoin] = useState();

  const [isInvitingFriends, setIsInvitingFriends] = useState(false);
  const [playerFriends, setPlayerFriends] = useState([]);
  const [invitedFriends, setInvitedFriends] = useState({});

  const [isLandscape, setIsLandscape] = useState(window.innerWidth > 500);

  const isDiffert = BaseInfo.gamesPvpUrlType[gameUrl] === "scoreGame";

  const [coinSelected, setCoinSelected] = useState(null);

  const notificationSound = new Howl({
    src: [notificaSOund],
    volume: 1,
  });

  useEffect(() => {
    setCurrentTokenPrice(1);
    //console.log(selectedBetChainToken);
    if (coinSelected) {
      getPriceToken(coinSelected.toLowerCase()).then((p) =>
        setCurrentTokenPrice(p)
      );
    }
  }, [coinSelected]);

  useEffect(() => {
    window.addEventListener("resize", (e) => {
      setIsLandscape(window.innerWidth > 500);
    });

    if (alreadyInGameWaitingData) {
      setIsWaiting(true);
      setPartID(alreadyInGameWaitingData.roomID);
      setSelectedIsOpen(alreadyInGameWaitingData.isOpen);
      /*   if(gameUrl === "race"){
                goToRaceGame(alreadyInGameWaitingData.roomID,alreadyInGameWaitingData.player1)
            }else{ */
      ascoltaArrivoGiocatore(alreadyInGameWaitingData.roomID);
      // }
      setSelectedLevl(alreadyInGameWaitingData.level);
    } else if (invitedToGame) {
      findPrivateRoom(invitedToGame.code);
      setSelectedIsOpen(false);
    } else {
      setIsSelectingLevel(numLevels[gameUrl] != null);
      getListRooms();
      getListRoomsInProg();
    }
  }, []);

  async function getListRooms() {
    setListRooms([]);
    const gameNameID = "games";
    const tenMinutesAgoTimestamp = Date.now() - 15 * 60 * 1000;
    let q = query(
      collection(db, gameNameID),
      where("gameUrl", "==", gameUrl),
      where("state", "==", "waiting"),
      where("isOpen", "==", true)
    );
    if (isDiffert) {
      q = query(q, where("differtPlayerFinished", "==", true));
    } else {
      q = query(q, where("creationDate", ">=", tenMinutesAgoTimestamp));
    }
    if (BaseInfo.gamesMaxPlayers[gameUrl] === 2) {
      q = query(q, where("players_count", "==", 1));
    }
    q = query(q, limit(10));

    const resList = await getDocs(q);
    let rooms = [];
    resList.docs.forEach((element) => {
      if (element.data().createdBy !== auth.currentUser.uid) {
        rooms.push({
          ...element.data(),
          id: element.id,
        });
      }
    });
    setListRooms(rooms);
  }
  async function getListRoomsInProg() {
    setListGamesInProcess([]);
    const gameNameID = "games";
    const tenMinutesAgoTimestamp = Date.now() - 30 * 60 * 1000;
    let q = query(
      collection(db, gameNameID),
      where("gameUrl", "==", "skybattle"),
      where("state", "==", "in_progress"),
      where("isOpen", "==", true),
      where("creationDate", ">=", tenMinutesAgoTimestamp),
      limit(10)
    );

    const resList = await getDocs(q);
    let rooms = [];
    resList.docs.forEach((element) => {
      rooms.push({
        ...element.data(),
        id: element.id,
      });
    });
    console.log(rooms);
    setListGamesInProcess(rooms);
  }

  async function createRoom(price, isOpen) {
    if (price === undefined) {
      showErrorNotification("Select a price!");
      return;
    }
    if (isOpen == null) {
      showErrorNotification("Open or Private?");
      return;
    }
    if (!coinSelected) {
      showErrorNotification("Select a token!");
      return;
    }

    try {
      setIsLoading(true);
      const funct = httpsCallable(functions, "actionRoomGame");
      const dat = {
        action: "create",
        tokenSymbol: coinSelected,
        usd_price: price,
        is_open: isOpen,
        username: tgUsernameGlob,
        game_url: gameUrl,
        level: selectedLevl ?? false,
      };
      try {
        const r = await funct(dat);
        if (r.data.success === false) {
          //showErrorNotification("Error");
          throw { msg: r.data.msg ?? null, success: false };
        }

        setPartID(r.data.data.gameID);
        logEvent(analytics, "create_room", {
          gameUrl: gameUrl,
          price: price,
          level: selectedLevl ?? false,
        });
        /*  if(gameUrl === "race"){
                    goToRaceGame(r.data.data.gameID, auth.currentUser.uid);
                    return;
                } */
        //console.log(r.data);

        if (r.data.playNow === true) {
          //score game differt
          logEvent(analytics, "play_pvp", {
            gameUrl: gameUrl,
            level: selectedLevl ?? false,
            price: price,
          });
          nav("/" + BaseInfo.gamesPvpUrlType[gameUrl], {
            state: {
              gameID: r.data.data.gameID,
              level: selectedLevl ?? false,
              price: price,
              url: gameUrl,
              isDiffert: true,
            },
          });
          return;
        }
        setIsWaiting(true);

        ascoltaArrivoGiocatore(r.data.data.gameID);
      } catch (e) {
        //console.log(e);
        showErrorNotification(e.msg ?? "Error creating room");
      }
    } catch (error) {
      //console.log(error);
    }
    setIsLoading(false);
  }

  function ascoltaArrivoGiocatore(gameID) {
    const partitaDocRef = doc(db, "games", gameID);
    const unsubscribe = onSnapshot(partitaDocRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        const partitaData = docSnapshot.data();
        //console.log(partitaData);
        setGameDocData({ ...partitaData, id: docSnapshot.id });
        if (
          partitaData.state == "waiting" &&
          partitaData.player2 != null &&
          partitaData.players != null
        ) {
          //arriva altro giocatore
          setIsInvitingFriends(false);
          //check se è host
          if (partitaData.createdBy === auth.currentUser.uid) {
            notificationSound.play();
          }
        } else if (partitaData.state == "in_progress") {
          //player1 ha detto di partitre
          unsubscribe();
          notificationSound.play();
          goToGame(
            gameID,
            partitaData.gameUrl,
            partitaData.players[0].username,
            partitaData.createdBy,
            partitaData.player2Name,
            partitaData.player2,
            partitaData.createdBy === auth.currentUser.uid,
            partitaData.level ?? false,
            partitaData.price,
            partitaData.players_count,
            partitaData.players
          );
        }
      } else {
        //console.log("Il documento non esiste");
        unsubscribe();
        nav("/?username=" + tgUsernameGlob + "&id=" + telegramID);
        setIsLoading(false);
        setIsWaiting(false);
      }
    });
  }

  async function startGame() {
    //console.log("start game");
    setIsLoading(true);
    const funct = httpsCallable(functions, "actionRoomGame");
    const dat = {
      action: "start",
      gameID: partID,
    };
    try {
      const r = await funct(dat);
      //console.log(r.data);
      if (r.data.success === false) {
        //showErrorNotification("Error");
        throw "error";
      }
      //console.log(r.data);
    } catch (e) {
      showErrorNotification("Error starting room");
      //console.log(e);
    }
    setIsLoading(false);
  }
  async function findPrivateRoom(code) {
    let q = query(
      collection(db, "games"),
      where("code", "==", code),
      where("state", "==", "waiting"),
      //where("isOpen", '==', false),
      // where("players_count", "==", 1),
      limit(1)
    );
    const roomRes = await getDocs(q);
    if (roomRes.empty) {
      showErrorNotification("No room with this code");
      return;
    }
    const roomFinded = {
      ...roomRes.docs[0].data(),
      id: roomRes.docs[0].id,
    };
    //console.log(roomFinded);
    beforeJoiningRoom(roomFinded);
    setSelectedIsOpen(false);
  }
  async function joinGameFromList(id, price) {
    if (
      price >
      (userBalances?.balances?.[coinSelected.toLowerCase()]?.usd_amount ?? 0)
    ) {
      showErrorNotification("Insufficent balance");
      return;
    }
    //console.log(id);
    try {
      setIsLoading(true);
      const funct = httpsCallable(functions, "actionRoomGame");
      const dat = {
        action: "join",
        gameID: id,
        tokenSymbol: coinSelected.toLowerCase(),
      };
      const r = await funct(dat);
      if (r.data.success !== true) {
        console.log(r.data);
        showErrorNotification("Error join");
        return;
      }

      logEvent(analytics, "join_room", {
        gameUrl: r.data.data.gameUrl,
        level: selectedLevl,
      });
      //console.log(r.data);
      setPartID(id);
      if (r.data.playNow) {
        //differt
        logEvent(analytics, "play_pvp", {
          gameUrl: r.data.data.gameUrl,
          level: r.data.data.level,
          price: r.data.data.price,
        });
        nav("/" + BaseInfo.gamesPvpUrlType[r.data.data.gameUrl], {
          state: {
            gameID: id,
            level: selectedLevl ?? false,
            price: price,
            url: r.data.data.gameUrl,
            isDiffert: true,
          },
        });
        return;
      }
      /*   if(gameUrl === "race"){
                goToRaceGame(id, r.data.data.p1uid);
                return;
            } */
      ascoltaArrivoGiocatore(id);
      setIsWaiting(true);
      //goToGame(id, r.data.data.p1, r.data.data.p1uid, tgUsernameGlob, auth.currentUser.uid, false, r.data.data.level, r.data.data.price);
    } catch (error) {
      console.error("Errore durante l'esecuzione della transazione:", error);
    }
    setIsLoading(false);
  }
  function goToGame(
    gameID,
    url,
    p1,
    p1Uid,
    p2,
    p2Uid,
    isHome,
    lev,
    price,
    playersCount,
    players
  ) {
    console.log(url, gameID);
    logEvent(analytics, "play_pvp", { gameUrl: url, level: lev, price: price });
    nav("/" + BaseInfo.gamesPvpUrlType[url], {
      state: {
        gameID: gameID,
        player1: p1,
        player2: p2,
        isCasa: isHome,
        p1UID: p1Uid,
        p2UID: p2Uid,
        level: lev,
        price: price,
        url: url,
        players_count: playersCount,
        players: players,
      },
    });
  }
  function goToSpectatorGame(gameID, url, playersCount) {
    logEvent(analytics, "watch_pvp", { gameUrl: url });
    nav("/" + BaseInfo.gamesPvpUrlType[url], {
      state: {
        gameID: gameID,
        url: url,
        isSpectator: 1,
        players_count: playersCount,
      },
    });
  }

  async function leaveGame(gameID) {
    setIsLoading(true);
    const partitaDocRef = doc(db, "games", gameID);
    await deleteDoc(partitaDocRef);
    logEvent(analytics, "delete_room", { gameUrl: gameUrl });
    nav("/");
    setIsLoading(false);
  }
  async function leaveGamePlayer2(gameID) {
    const partitaDocRef = doc(db, "games", gameID);
    const newDat = {
      players: arrayRemove({
        uid: auth.currentUser.uid,
        username: auth.currentUser.displayName,
      }),
      players_count: gameDocData.players_count - 1,
      [`bets.${auth.currentUser.uid}`]: deleteField(),
    };
    await updateDoc(partitaDocRef, newDat);
    nav("/?username=" + tgUsernameGlob + "&id=" + telegramID);
    setIsLoading(false);
  }
  function beforeJoiningRoom(room) {
    setSelectedRoomToJoin(room);
    setSelectedLevl(room.level);
    setIsSelectingTokenJoinRoom(true);
  }

  function SingleRoom({ room }) {
    return (
      <div
        onClick={() => beforeJoiningRoom(room)}
        style={{
          backgroundColor: "#2c1e35",
          padding: "22px",
          borderRadius: "12px",
          marginTop: "12px",
        }}
      >
        <RRow horizontalAlign="space-between" verticalAlignment="top">
          <RColumn horizontalAlign="start">
            <RText weight="bold" size="20px">
              {room.players[0].username}
            </RText>
            {room.level != false && <RText>Level {room.level}</RText>}
            {!isDiffert && (
              <RText weight="bold">
                Players waiting: {room.players_count ?? 1}
              </RText>
            )}
          </RColumn>
          <RColumn horizontalAlign="end">
            <RText size="16px">{formatTimeAgoMinutes(room.creationDate)}</RText>
            <RText weight="bold" size="22px">
              {room.price}$
            </RText>
          </RColumn>
        </RRow>
      </div>
    );
  }
  function SingleRoomInProgress({ room }) {
    return (
      <div
        onClick={() =>
          goToSpectatorGame(room.id, room.gameUrl, room.players_count)
        }
        style={{
          backgroundColor: "#2c1e35",
          padding: "22px",
          borderRadius: "12px",
          marginTop: "12px",
        }}
      >
        <RRow horizontalAlign="space-between" verticalAlignment="top">
          <RColumn horizontalAlign="start">
            <RText weight="bold" size="20px">
              {room.players[0].username}
            </RText>
            {room.level != false && <RText>Level {room.level}</RText>}
            <RText weight="bold">Players: {room.players_count ?? 1}</RText>
          </RColumn>
          <RColumn horizontalAlign="end">
            <RText size="16px">{formatTimeAgoMinutes(room.creationDate)}</RText>
            <RText weight="bold" size="22px">
              {room.price}$
            </RText>
          </RColumn>
        </RRow>
      </div>
    );
  }
  function RoomList() {
    return (
      <>
        {listGameInProcess.length > 0 && (
          <RText align={"center"} size="18px" weight="bold">
            Live matches
          </RText>
        )}
        {listGameInProcess.map((room, index) => (
          <SingleRoomInProgress room={room} key={index} />
        ))}
        <Space />
        {listRooms.length > 0 && (
          <RText align={"center"} size="18px" weight="bold">
            {isDiffert ? "Challenges" : "Rooms"}
          </RText>
        )}
        {listRooms.map((room, index) => (
          <SingleRoom room={room} key={index} />
        ))}
      </>
    );
  }
  useEffect(() => {
    if (!isInvitingFriends) return;

    const friendRef = collection(db, "users", auth.currentUser.uid, "friends");
    const q = query(friendRef, where("state", "==", "confirmed"));
    getDocs(q).then((snapshot) => {
      const frienndss = [];
      snapshot.docs.forEach((element) => {
        frienndss.push({ ...element.data(), code: element.id });
      });
      setPlayerFriends(frienndss);
    });
  }, [isInvitingFriends]);
  if (isLoading) {
    return <LoadingScreen />;
  }
  async function inviteFriend(code) {
    setInvitedFriends((prev) => ({ ...prev, [code]: true }));
    const funct = httpsCallable(functions, "addFriendInPvp");
    const param = {
      friendCode: code,
      gameCode: gameDocData.code,
      gameUrl: gameDocData.gameUrl,
      gamePrice: gameDocData.price,
      gameLevel: gameDocData.level,
    };
    try {
      await funct(param);
    } catch (error) {
      showErrorNotification("Error invite friend");
    }
  }
  function InvititeFriendModal() {
    return (
      <div
        style={{
          width: "100%",
          height: "90%",
          position: "fixed",
          bottom: 0,
          backgroundColor: ColorPalette.bgColor,
        }}
      >
        <RColumn>
          <Space />
          <RTitile>Invite friends</RTitile>
          <div
            onClick={() => setIsInvitingFriends(false)}
            style={{
              position: "absolute",
              right: "14px",
              top: "10px",
              cursor: "pointer",
            }}
          >
            <RText>close</RText>
          </div>
          <Space />
          {playerFriends.map((friend, ind) => (
            <div
              key={ind}
              style={{
                width: "95%",
                backgroundColor: "#2c1e35",
                borderRadius: "12px",
                marginTop: "12px",
              }}
            >
              <Padding padding={"12px"}>
                <RRow horizontalAlign="space-between">
                  <RText weight="bold" size="20px">
                    {friend.username}
                  </RText>
                  {!invitedFriends[friend.code] && (
                    <SecondaryBtn
                      text={"Invite"}
                      onClick={() => inviteFriend(friend.code)}
                    />
                  )}
                  {invitedFriends[friend.code] && (
                    <RText color="grey">Invited</RText>
                  )}
                </RRow>
              </Padding>
            </div>
          ))}
        </RColumn>
      </div>
    );
  }
  if (isWaiting) {
    return (
      <>
        <ToastContainer />
        {isInvitingFriends && <InvititeFriendModal />}
        <RColumn
          height="100vh"
          verticalAlignment={isLandscape ? "space-between" : "start"}
          horizontalAlign="center"
        >
          <RRow>
            <img
              src={`/gameIcons/${gameUrl}.png`}
              width={isLandscape ? "80px" : "120px"}
            />
            {selectedLevl && (
              <RText weight="bold" size="18px">
                Level {selectedLevl}
              </RText>
            )}
          </RRow>

          {gameDocData &&
            gameDocData.players_count < BaseInfo.gamesMaxPlayers[gameUrl] && (
              <RText weight="bold" size="22px">
                {selectedIsOpen ? "Waiting opponent..." : "Waiting friends..."}
              </RText>
            )}
          <Space />
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: isLandscape ? "row" : "column-reverse",
              justifyContent: isLandscape ? "center" : "start",
            }}
          >
            {!isLandscape && <Space />}
            <RColumn width={"100%"}>
              {gameDocData && (
                <>
                  <RRow>
                    <RText size="20px" weight="bold">
                      Players in room{" "}
                      {gameDocData.players_count > 1
                        ? gameDocData.players_count
                        : ""}
                    </RText>
                    {gameDocData && (
                      <>
                        <SpaceHorizontal />
                        {gameDocData.players_count <
                          BaseInfo.gamesMaxPlayers[gameUrl] && (
                          <TextButton
                            onClick={() => setIsInvitingFriends(true)}
                          >
                            Add friends
                          </TextButton>
                        )}
                      </>
                    )}
                  </RRow>

                  {gameDocData.players.map((player, ind) => (
                    <div
                      key={player.uid}
                      style={{ width: "100%", maxWidth: "400px" }}
                    >
                      <ContainerBorder
                        heightMobile=""
                        heightPc=""
                        width="95%"
                        padding="12px 20px"
                        margin="12px 0px"
                      >
                        <RText align={"center"} weight="bold" size="20px">
                          {player.username}
                        </RText>
                      </ContainerBorder>
                    </div>
                  ))}
                </>
              )}
            </RColumn>
            {!isLandscape && <Space height="50px" />}
            <RColumn width={"100%"}>
              {gameDocData && (
                <>
                  <RText size="18px">Code</RText>
                  <RText normalFont size="26px" weight="bold">
                    {gameDocData.code}
                  </RText>
                  <div
                    onClick={() =>
                      copyString(
                        "t.me/TheGameHubBot?start=game=" + gameDocData.code,
                        "Invite link copied"
                      )
                    }
                  >
                    <RRow>
                      <img src={copyIcon} width={"18px"} />
                      <SpaceHorizontal width="6px" />
                      <RText normalFont>
                        {"t.me/TheGameHubBot?start=game="}
                        {gameDocData.code}
                      </RText>
                    </RRow>
                  </div>
                  <Space />
                  {isLandscape && (
                    <>
                      {gameDocData.players_count > 1 &&
                        gameDocData.createdBy === auth.currentUser.uid && (
                          <PrimaryBtn text={"Start Game"} onClick={startGame} />
                        )}
                      <Space />
                      {gameDocData.createdBy === auth.currentUser.uid && (
                        <SecondaryBtn
                          text={"Cancel room"}
                          onClick={() => leaveGame(partID)}
                        ></SecondaryBtn>
                      )}
                      {gameDocData.createdBy !== auth.currentUser.uid && (
                        <SecondaryBtn
                          text={"Leave room"}
                          onClick={() => leaveGamePlayer2(partID)}
                        ></SecondaryBtn>
                      )}
                    </>
                  )}
                </>
              )}
            </RColumn>
          </div>

          {!isLandscape && gameDocData && (
            <>
              {gameDocData.players_count > 1 &&
                gameDocData.createdBy === auth.currentUser.uid && (
                  <PrimaryBtn text={"Start Game"} onClick={startGame} />
                )}
              <Space />
              {gameDocData.createdBy === auth.currentUser.uid && (
                <SecondaryBtn
                  text={"Cancel room"}
                  onClick={() => leaveGame(partID)}
                ></SecondaryBtn>
              )}
              {gameDocData.createdBy !== auth.currentUser.uid && (
                <SecondaryBtn
                  text={"Leave room"}
                  onClick={() => leaveGamePlayer2(partID)}
                ></SecondaryBtn>
              )}
            </>
          )}

          <Space height="38px" />
        </RColumn>
      </>
    );
  }
  let oneTimeNoBalanceNot = false;
  function onSelectRoomPriceChoose(price) {
    if (
      price >
      (userBalances?.balances?.[coinSelected?.toLowerCase() ?? ""]
        ?.usd_amount ?? 0)
    ) {
      if (!oneTimeNoBalanceNot) {
        showErrorNotification("Insufficent balance");
        oneTimeNoBalanceNot = true;
      }
      return;
    }
    setSelectedRoomPrice(price);
  }

  function Levels() {
    const numeri = Array.from(
      { length: numLevels[gameUrl] },
      (_, index) => index + 1
    );
    return (
      <>
        <RText weight="bold" size="22px" align={"center"}>
          Select Level
        </RText>
        <Space />
        <div
          style={{
            flexWrap: "wrap",
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          {numeri.map((num, index) => (
            <div
              onClick={() => setSelectedLevl(num)}
              key={"pri" + index}
              style={{
                width: "40%",
                border:
                  selectedLevl == num
                    ? "2px solid " + ColorPalette.accentColor
                    : "2px solid rgba(0, 0, 0, 0)",
                padding: "4px",
                margin: "3px 0px",
                borderRadius: "10px",
              }}
            >
              <RText align={"center"} weight="bold" size="20px">
                Level {num}
              </RText>
            </div>
          ))}
        </div>
      </>
    );
  }
  function BalancesSelect() {
    return (
      <>
        <RText size="16px">Select token to bet</RText>
        <Space height="12px" />
        {BaseInfo.baseInfoDoc.coinSupported
          .filter((v) => v.symbol !== "GHUB")
          .map((v, i) => (
            <ContainerBorder
              width="92%"
              gradientBg={v.symbol === coinSelected}
              key={v.symbol}
              padding="10px"
              onClick={() => {
                setCoinSelected(v.symbol);
                setSelectedRoomPrice();
              }}
            >
              <RRow horizontalAlign="space-between">
                <RRow>
                  <img src={v.img} width={"18px"} />
                  <SpaceHorizontal />
                  <RText size="18px">{v.symbol}</RText>
                </RRow>
                <RColumn horizontalAlign="end">
                  <RText size="15px">
                    $
                    {formatNumber(
                      userBalances?.balances?.[v.symbol.toLowerCase()]
                        ?.usd_amount ?? 0,
                      2
                    )}
                  </RText>
                  <RText color="grey" size="14px">
                    {formatNumber(
                      userBalances?.balances?.[v.symbol.toLowerCase()]
                        ?.amount ?? 0,
                      5
                    )}
                  </RText>
                </RColumn>
              </RRow>
            </ContainerBorder>
          ))}
      </>
    );
  }
  if (isSelectingTokenJoinRoom) {
    return (
      <>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
        />
        <BackToHomeBtn />
        <RColumn width={"100%"}>
          <Space height="50px" />
          <RTitile size="30px" color={ColorPalette.accentColor}>
            {BaseInfo.gamesName[selectedRoomToJoin.gameUrl]}
          </RTitile>
          {selectedRoomToJoin.level && (
            <RText>Level: {selectedRoomToJoin.level}</RText>
          )}
          <Space height="8px" />
          <RText color="grey">@{selectedRoomToJoin.players[0].username}</RText>
          <Space />
          <RText size="18px">Bet ${selectedRoomToJoin.price}</RText>
          <Space />
          <BalancesSelect />
          <Space height="50px" />
          <PrimaryBtn
            onClick={() =>
              joinGameFromList(selectedRoomToJoin.id, selectedRoomToJoin.price)
            }
            text={"JOIN"}
          />
        </RColumn>
      </>
    );
  }
  const scale = (value) => {
    const previousMarkIndex = Math.floor(value / 25);
    const previousMark = pricesMarks[previousMarkIndex];
    const remainder = value % 25;
    if (remainder === 0) {
      return previousMark.scaledValue;
    }
    const nextMark = pricesMarks[previousMarkIndex + 1];
    const increment = (nextMark.scaledValue - previousMark.scaledValue) / 25;
    return remainder * increment + previousMark.scaledValue;
  };
  if (isCreateRoom) {
    return (
      <>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
        />
        <BackToHomeBtn />

        <RColumn width={"98%"}>
          <Space height="60px" />
          <RRow horizontalAlign="space-between" width={"70%"}>
            <div
              onClick={() => setSelectedIsOpen(true)}
              style={{
                cursor: "pointer",
                width: "40%",
                border: selectedIsOpen
                  ? "2px solid " + ColorPalette.accentColor2
                  : "",
                padding: "6px 12px",
                margin: "3px 0px",
                borderRadius: "10px",
              }}
            >
              <RText align={"center"} weight="bold" size="20px">
                OPEN
              </RText>
            </div>
            <div
              onClick={() => setSelectedIsOpen(false)}
              style={{
                cursor: "pointer",
                width: "40%",
                border: !selectedIsOpen
                  ? "2px solid " + ColorPalette.accentColor2
                  : "",
                padding: "6px 12px",
                margin: "3px 0px",
                borderRadius: "10px",
              }}
            >
              <RText align={"center"} weight="bold" size="20px">
                PRIVATE
              </RText>
            </div>
          </RRow>
          <Space height="30px" />

          {isSelectingLevels && <Levels />}
          {!isSelectingLevels && (
            <>
              <BalancesSelect />
              <Space />
              <RText>Select bet amount</RText>
              <Space height="2px" />
              <Slider
                style={{ width: "65%", color: ColorPalette.accentColor }}
                value={
                  pricesMarks.find((p) => p.scaledValue === selectedRoomPrice)
                    ?.value ?? null
                }
                onChange={(e, v) =>
                  onSelectRoomPriceChoose(
                    pricesMarks.find((p) => p.value === v).scaledValue
                  )
                }
                step={1}
                marks={pricesMarks}
                scale={scale}
                min={isTest ? 1 : 2}
                max={10}
              />
              <Space height="4px" />
              {selectedRoomPrice !== undefined && (
                <RText color="white" align={"center"} weight="bold" size="20px">
                  {selectedRoomPrice}$ -{" "}
                  {(selectedRoomPrice / currentTokenPrice).toFixed(
                    selectedRoomPrice / currentTokenPrice < 1 ? 5 : 1
                  )}{" "}
                  {coinSelected}
                </RText>
              )}
              {/* <RColumn width={"80%"}>
             {prices.map((price, index) => (
                    <div onClick={() => onSelectRoomPriceChoose(price)} key={"pri" + index} style={{width: "100%",border: selectedRoomPrice == price ? "2px solid " + ColorPalette.accentColor : "2px solid rgba(0, 0, 0, 0)", padding: "4px", margin: "3px 0px", borderRadius: "10px"}}>
                        <RText color={(price / currentTokenPrice > getUserBalances()[selectedBetChainToken]?.native?.balance ?? 0) ? "lightgray" : "white"} 
                        align={"center"} weight="bold" size="20px">
                            {price}$ - {(price / currentTokenPrice).toFixed(price / currentTokenPrice < 1 ? 5 : 1)} {BaseInfo.chainTokensData[selectedBetChainToken]?.symbol ?? "d"}</RText>
                    </div>
             ))}
            </RColumn> */}
            </>
          )}
          <Space />
          {isSelectingLevels && (
            <PrimaryBtn
              onClick={() => setIsSelectingLevel(false)}
              text={"Next"}
            />
          )}
          {!isSelectingLevels && (
            <PrimaryBtn
              onClick={() => createRoom(selectedRoomPrice, selectedIsOpen)}
              text={"Create"}
            />
          )}
        </RColumn>
      </>
    );
  }
  return (
    <div style={{ background: ColorPalette.bgGradient, minHeight: "100vh" }}>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
      />
      <BackToHomeBtn />
      <img
        onClick={getListRooms}
        src={reloadImg}
        width={"24px"}
        style={{ position: "fixed", top: "22px", right: "22px" }}
      />
      <Space height="80px" />
      <RColumn>
        <RRow width={"100%"} maxWidth={"400px"}>
          <InputField
            padding="10px 10px"
            maxChar={6}
            value={insertedGameCode}
            setValue={(e) =>
              setInsertedGameCode(e.target.value.toString().toUpperCase())
            }
            placeHolder={"Game code"}
          />
          <SpaceHorizontal />
          <SecondaryBtn
            onClick={() => findPrivateRoom(insertedGameCode)}
            text={"Play"}
          />
        </RRow>

        <RText align={"center"}>or</RText>
        <PrimaryBtn
          onClick={() => setIsCreateRoom(true)}
          text={"Create room"}
        />

        <Space />
      </RColumn>
      <RColumn>
        <div style={{ width: "98%", maxWidth: "560px" }}>
          <RoomList />
        </div>
      </RColumn>
    </div>
  );
};

export default LobbyPage;
