export function showAds(blockId) {
  const AdController = window.Adsgram.init({
    blockId: blockId,
    debug: false,
    debugBannerType: "RewardedVideo",
  });
  AdController.show()
    .then((result) => {
      console.log(result);
    })
    .catch((result) => {
      console.log(result);
    });
}
