import {
  Padding,
  RColumn,
  RRow,
  RText,
  RTitile,
  Space,
} from "../compnents/otherCOmps";
import level1Img from "../assets/level1.png";
import level2Img from "../assets/level2.png";
import level3Img from "../assets/level3.png";
import { BaseInfo, ColorPalette } from "../global/const";
import { getLevelInd } from "../global/util";

export default function LevelsPage() {
  const levelsImg = [null, level1Img, level2Img, level3Img];

  let singleColumnWidth = window.innerWidth / 4 - 2;
  if (singleColumnWidth > 640 / 4) singleColumnWidth = 640 / 4;

  function SingleLvl({ data }) {
    const index = data.index;
    return (
      <div
        style={{
          backgroundColor:
            index === getLevelInd() ? ColorPalette.accentColor2Bg : null,
          borderRadius: "16px",
          border:
            index === getLevelInd()
              ? "solid 2px " + ColorPalette.accentColor2
              : "",
        }}
      >
        <RColumn width={singleColumnWidth}>
          {levelsImg[index] ? (
            <img src={levelsImg[index]} width={"45px"} />
          ) : (
            <div style={{ height: "45px" }}></div>
          )}
          <Space height="4px" />
          <RText
            weight="bold"
            size={index === 1 ? "12px" : "16px"}
            color={index >= 3 ? "#F7EA4E" : "white"}
          >
            {data.name}
          </RText>
          {index > 0 && <RText>{">" + data.tokens}</RText>}
          <Space />
        </RColumn>
      </div>
    );
  }
  return (
    <>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div style={{ maxWidth: "550px", width: "100%" }}>
          <RColumn width={"100%"}>
            <Space />
            <RTitile>LEVELS</RTitile>
            <Space />
            <RText size="17px">By GHUB amount in wallet</RText>
            <Space height="8px" />
            <RRow horizontalAlign="center" width={"100%"}>
              {BaseInfo.baseInfoDoc.levels.map((val) => (
                <SingleLvl key={val.name} data={val} />
              ))}
            </RRow>
            <Space height="42px" />
            <RText weight="bold" size="20px" color={ColorPalette.accentColor}>
              Daily free P2E attempts
            </RText>
            <Space height="12px" />
            <RRow horizontalAlign="center" width={"100%"}>
              {BaseInfo.baseInfoDoc.levels.map((val, ind) => (
                <div style={{ width: singleColumnWidth }} key={ind}>
                  <RText size="18px" align={"center"}>
                    {val.attemptP2E}
                  </RText>
                </div>
              ))}
            </RRow>
            <Space height="42px" />
            <RText weight="bold" size="20px" color={ColorPalette.accentColor}>
              P2E 3 attemps cost
            </RText>
            <Space height="12px" />
            <RRow horizontalAlign="center" width={"100%"}>
              {BaseInfo.baseInfoDoc.levels.map((val, ind) => (
                <div style={{ width: singleColumnWidth }} key={ind}>
                  <RText size="18px" align={"center"}>
                    ${val.priceP2E}
                  </RText>
                </div>
              ))}
            </RRow>

            <Space height="42px" />
            <RText weight="bold" size="20px" color={ColorPalette.accentColor}>
              CashBack on PVP games
            </RText>
            <Space height="12px" />
            <RRow horizontalAlign="center" width={"100%"}>
              {BaseInfo.baseInfoDoc.levels.map((val, ind) => (
                <div style={{ width: singleColumnWidth }} key={ind}>
                  <RText size="18px" align={"center"}>
                    {val.cashbackPVP}%
                  </RText>
                </div>
              ))}
            </RRow>
            <Space height="42px" />
            <RText weight="bold" size="20px" color={ColorPalette.accentColor}>
              Weekly lottery tickets
            </RText>
            <Space height="12px" />
            <RRow horizontalAlign="center" width={"100%"}>
              {BaseInfo.baseInfoDoc.levels.map((val, ind) => (
                <div style={{ width: singleColumnWidth }} key={ind}>
                  <RText size="18px" align={"center"}>
                    {val.lotteryTicketsWeek}
                  </RText>
                </div>
              ))}
            </RRow>
          </RColumn>
        </div>
      </div>
    </>
  );
}
