import { RColumn, RText, RTitile, Space } from "../compnents/otherCOmps";
import logoImg from "../assets/logo.png";
import { versionGame } from "../global/const";
import { useEffect, useState } from "react";
import { useGameType } from "../theme";

export default function NoActivePage({ msg }) {
  const [tapCount, setTapCount] = useState(0);

  const { setIsTestInProd } = useGameType();

  useEffect(() => {
    console.log(tapCount);

    if (tapCount >= 5) {
      setIsTestInProd(true);
    }
  }, [tapCount]);
  return (
    <RColumn>
      <Space height="40px" />
      <img
        onClick={() => setTapCount((p) => p + 1)}
        src={logoImg}
        width={"150px"}
      />
      <Space height="40px" />
      <RTitile>Currently offline</RTitile>
      <Space />
      <RText>{msg}</RText>
    </RColumn>
  );
}
