import { ColorPalette } from "../global/const";
import {
  getDateFinishP2eComp,
  getDateStartP2eComp,
  getLevelInd,
  isP2EStarting,
} from "../global/util";
import { levelImgs } from "../home/tabController";

import newImg from "../assets/new.png";
import { RColumn, RText } from "./otherCOmps";
import "./glow.css";
import { useEffect, useState } from "react";
import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
} from "date-fns";
export function popUpModal({ children }) {
  return (
    <div
      style={{
        position: "fixed",
        width: "100v2",
        height: "100vh",
        zIndex: 30,
        background:
          "linear-gradient(to right, rgba(9, 22, 30, 0.8), rgba(0, 0, 0, 0.8))",
      }}
    >
      <div
        style={{
          position: "absolute",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "20px",
        }}
      >
        {children}
      </div>
    </div>
  );
}
export function BadgeWhiteBg({ size = "32px" }) {
  return (
    <>
      {getLevelInd() > 0 && (
        <div
          style={{
            backgroundColor: "rgb(255, 240, 253)",
            boxShadow: "0 0 15px rgb(255, 118, 118)",
            borderRadius: "8px",
            width: size,
            height: size,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={levelImgs[getLevelInd() - 1]} width={"24px"} />
        </div>
      )}
    </>
  );
}
export function ContainerBorder({
  children,
  border = true,
  gradientBg = false,
  onClick,
  width = "100%",
  margin = "6px 0px",
  padding = "12px 20px",
  heightMobile = "",
  heightPc = "",
  isNew = false,
  isMobile = window.innerWidth < 600,
  cursorPointer = false,
}) {
  const bgCol = !gradientBg
    ? `#1D1542`
    : `linear-gradient(to left, ${ColorPalette.accentColor2}, ${ColorPalette.accentColor2Bg})`;
  const shadow = !gradientBg
    ? "0px 0px 12px rgba(11, 153, 224, 0.2)"
    : "0px 0px 30px rgba(11, 153, 224, 0.4)";
  const bb = "solid " + ColorPalette.accentColor2 + " 2px";
  return (
    <>
      <div
        onClick={onClick}
        style={{
          margin: margin,
          width: width,
          cursor: cursorPointer ? "pointer" : null,
        }}
      >
        <div
          style={{
            position: "relative",
            background: border ? bgCol : "#2d264a",
            height: isMobile ? heightMobile : heightPc,
            padding: padding,
            borderRadius: "10px",
            border: border ? bb : undefined,
            boxShadow: shadow,
          }}
        >
          {isNew && (
            <div style={{ position: "absolute", right: "-16px", top: "-16px" }}>
              <NewIcon width="32px" rotation={"-15deg"} />
            </div>
          )}
          {children}
        </div>
      </div>
    </>
  );
}

export function NewIcon({ width = "24px", rotation = null }) {
  return <img src={newImg} width={width} style={{ rotate: rotation }} />;
}

export function OutlineBorderBtn({ children, onClick, isSelected, width }) {
  return (
    <div
      onClick={onClick}
      style={{
        cursor: "pointer",
        width: width,
        border: isSelected
          ? "2px solid " + ColorPalette.accentColor2
          : "2px soldi transparent",
        padding: "6px 12px",
        margin: "3px 0px",
        borderRadius: "10px",
      }}
    >
      <RText align={"center"} weight="bold" size="20px">
        {children}
      </RText>
    </div>
  );
}

export function MaxWidthContainer({ children }) {
  return (
    <RColumn width={"100%"}>
      <div style={{ maxWidth: "600px", width: "100%" }}>
        <div style={{ padding: "12px" }}>{children}</div>
      </div>
    </RColumn>
  );
}
export function TabButton({
  children,
  onClick,
  selected = false,
  width = null,
}) {
  return (
    <div
      onClick={onClick}
      style={{
        textAlign: "center",
        width: width,
        cursor: "pointer",
        borderRadius: "12px",
        padding: "10px 14px",
        border: selected
          ? "2px solid " + ColorPalette.accentColor2
          : "2px solid transparent",
      }}
    >
      <RText>{children}</RText>
    </div>
  );
}
export function GlowBtn({ children }) {
  return (
    <>
      <button className="glow-btn">
        <span className="text">{children}</span>
        <span className="shimmer"></span>
      </button>
    </>
  );
}

export const CountdownTimer = () => {
  const utcEnd = getDateFinishP2eComp(); // Funzione per ottenere la data di fine in UTC

  const utcStart = getDateStartP2eComp();

  const calculateTimeLeft = () => {
    const isStarting = isP2EStarting();
    const now = new Date();

    const targetDate = isStarting ? utcStart : utcEnd;

    if (now >= targetDate) {
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    const days = differenceInDays(targetDate, now);
    const hours = differenceInHours(targetDate, now) % 24;
    const minutes = differenceInMinutes(targetDate, now) % 60;
    const seconds = differenceInSeconds(targetDate, now) % 60;

    return { days, hours, minutes, seconds };
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <ContainerBorder>
      {!isP2EStarting() && (
        <RText align={"center"}>
          Close in{" "}
          {`${timeLeft.days > 0 ? timeLeft.days + "d" : ""} ${String(
            timeLeft.hours
          ).padStart(2, "0")}h ${String(timeLeft.minutes).padStart(
            2,
            "0"
          )}m ${String(timeLeft.seconds).padStart(2, "0")}s`}
        </RText>
      )}
      {isP2EStarting() && (
        <RText align={"center"}>
          Starts in{" "}
          {`${String(timeLeft.hours).padStart(2, "0")}h ${String(
            timeLeft.minutes
          ).padStart(2, "0")}m ${String(timeLeft.seconds).padStart(2, "0")}s`}
        </RText>
      )}
    </ContainerBorder>
  );
};

export const HorizontalScrollContainer = ({ children }) => {
  return <div className="horizontal-scroll">{children}</div>;
};
export const HorizontalScrollElement = ({ children }) => {
  return <div className="scroll-item">{children}</div>;
};
