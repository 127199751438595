import { httpsCallable } from "firebase/functions";
import React, { createContext, useContext, useState } from "react";
import { functions } from "./firebase";
import { getPriceToken } from "./global/apis";

export const GameTypeContext = createContext();

export const GameTypeProvider = ({ children }) => {
  const [gameType, setGameType] = useState("init"); // Valore iniziale

  const [userBalances, setUserBalances] = useState({
    balances: {},
    total_usd: 0,
    isLoading: true,
  });

  const [isTestInProd, setIsTestInProd] = useState(false);

  const updateUserBalances = async (userBalancesFromDoc) => {
    //get balance
    /* const funct = httpsCallable(functions, "getMyBalance");

    const r = await funct();
    if (!r.data.success) {
      console.log(r.data);
      setUserBalances({ balances: {}, total_usd: 0, isLoading: false });
    } else {
      console.log(r.data);
      setUserBalances({ ...r.data, isLoading: false });
    } */
    if (!userBalancesFromDoc) return;
    const coins = Object.entries(userBalancesFromDoc);
    let totUsd = 0;
    let formattedBalances = userBalancesFromDoc;
    for (const [coin, v] of coins) {
      let usdC = 0;
      console.log(coin);
      try {
        const usdV = await getPriceToken(coin.toLowerCase());
        if (isNaN(usdV)) continue;
        const totUsdVal = usdV * (v.amount ?? 0);
        formattedBalances[coin].usd_amount = totUsdVal;
        usdC += totUsdVal;
      } catch (error) {
        console.log(error);
      }
      totUsd += usdC;
    }

    setUserBalances({
      balances: formattedBalances,
      total_usd: totUsd,
      isLoading: false,
    });
  };

  return (
    <GameTypeContext.Provider
      value={{
        gameType,
        setGameType,
        userBalances,
        updateUserBalances,
        isTestInProd,
        setIsTestInProd,
      }}
    >
      {children}
    </GameTypeContext.Provider>
  );
};

export const useGameType = () => {
  return useContext(GameTypeContext);
};
