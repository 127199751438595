import "./App.css";
import {
  BrowserRouter,
  Routes,
  Route,
  MemoryRouter,
  useNavigate,
} from "react-router-dom";
import Starting from "./aStartingPage";
import { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import {
  showErrorNotification,
  showSuccessNotification,
} from "./compnents/errorMessagesComp";
import { GameTypeProvider } from "./theme";

import { isMobile, isDesktop } from "react-device-detect";

function App() {
  useEffect(() => {
    if (window.Telegram.WebApp) {
      window.Telegram.WebApp.ready();
      window.Telegram.WebApp.expand();
    }
  }, []);
  return (
    <MemoryRouter>
      <ToastContainer />
      <GameTypeProvider>
        <Starting />
      </GameTypeProvider>
    </MemoryRouter>
  );
}

export default App;
