import {
  BackToHomeBtn,
  PrimaryBtn,
  RColumn,
  RRow,
  RText,
  RTitile,
  Space,
  SpaceHorizontal,
} from "../compnents/otherCOmps";
import wheelImg from "../assets/wheel.png";
import ballImg from "../assets/ball.png";
import "./rouletteSTyle.css";
import { useEffect, useState } from "react";
import { BaseInfo, ColorPalette, getUserBalances } from "../global/const";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../compnents/errorMessagesComp";
import { ToastContainer } from "react-toastify";
import { httpsCallable } from "firebase/functions";
import { analytics, functions } from "../firebase";
import { logEvent } from "firebase/analytics";
//import { getRealBalance } from "../global/util";

let loopRotation;
let loopLoading;
export default function RouletteGame() {
  const [rotation, setRotation] = useState(0);
  const [ballRotation, setBallRotation] = useState(0);
  const [ballPosition, setBallPosition] = useState({ x: 0, y: 0 }); // Posiziona la palla inizialmente sopra la ruota
  const [balStopped, setBalStopeed] = useState(false);
  const [distFromCenter, setDistFromCenter] = useState(120);
  const [numToEsc, setNumToEsc] = useState(-1);
  const [totWinRes, setTotWinRes] = useState({ win: 0, totBet: 0 });
  const [isSpinning, setIsSpinning] = useState(false);
  const [userBalance, setUserBalance] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const [isMobile, setIsMobile] = useState(true);

  const tokenSymbol =
    BaseInfo.chainTokensData[BaseInfo.gameTokenAddress].symbol;

  const numToRot = {
    1: 155,
    2: 50,
    3: 5,
    4: 110,
    5: 125,
    6: 80,
    7: 200,
    8: 215,
    9: 140,
    10: 95,
    11: 170,
    12: 65,
  };

  //const [selectedBetAmount, setSelectedBetAmount] = useState();
  const [selectedBets, setSelectedBets] = useState({});
  const [isSelectingBet, setIsSelectingBet] = useState();

  const betPrices = [1, 2, 3, 4, 5, 6, 10, 15, 20, 25, 30, 40];

  const usdPerToken = BaseInfo.baseInfoDoc.usdPerGameToken;

  const colorToNums = {
    red: [1, 5, 10, 12, 8, 3],
    black: [2, 7, 11, 9, 4, 6],
  };

  useEffect(() => {
    logEvent(analytics, "play_pve", { gameUrl: "roulette" });

    setIsMobile(window.innerWidth < 600);
  }, []);

  useEffect(() => {
    if (!balStopped) setDistFromCenter((p) => Math.max(p - 0.12, 70));

    const radianRotation = ballRotation * (Math.PI / 180); // Converti la rotazione in radianti
    const newX = distFromCenter * Math.cos(radianRotation);
    const newY = distFromCenter * Math.sin(radianRotation);
    setBallPosition({ x: newX, y: newY });
  }, [ballRotation, balStopped]);

  useEffect(() => {
    if (isLoading) return;
    if (rotation >= 360 + numToRot[numToEsc]) {
      setBallRotation(rotation - numToRot[numToEsc] * 2);
      setBalStopeed(true);
    }
  }, [rotation, isLoading]);

  useEffect(() => {
    if (balStopped) fineSpin();
  }, [balStopped]);

  function fineSpin() {
    setBalStopeed(false);
    setIsSpinning(false);
    clearInterval(loopRotation);
    const totalWin = totWinRes.win - totWinRes.totBet;
    if (totalWin > 0) {
      showSuccessNotification(
        "You win " + totalWin.toFixed(2).toString() + tokenSymbol
      );
    } else {
      showErrorNotification("You lose", 1000);
    }
    setUserBalance((p) => p + totalWin);
  }

  async function play() {
    setBallRotation(0);
    setDistFromCenter(120);
    setBallPosition({ x: 0, y: 0 });
    setIsLoading(true);
    const funct = httpsCallable(functions, "playRoulette");
    const p = {
      bets: selectedBets,
    };
    try {
      const res = await funct(p);
      const bToEsc = res.data.bToEsc;
      setTotWinRes({ win: res.data.totWin, totBet: res.data.totTokensBetted });
      logEvent(analytics, "bet_roulette", selectedBets);
      beforeSpin(bToEsc);
    } catch (error) {
      showErrorNotification("There was an error");
    }
    setIsLoading(false);
  }
  useEffect(() => {
    if (isLoading) {
      loopLoading = setInterval(() => {
        setRotation((p) => p + 1);
      }, 10);
    } else {
      clearInterval(loopLoading);
    }
  }, [isLoading]);

  function beforeSpin(res) {
    if (isSpinning) return;
    setIsSpinning(true);
    setRotation(0);
    setBallRotation(0);
    setDistFromCenter(120);
    setBalStopeed(false);
    spinToNum(res);
  }
  function spinToNum(num) {
    setNumToEsc(num);

    loopRotation = setInterval(() => {
      setRotation((prevRotation) => prevRotation + 1); // Incrementa la rotazione della roulette

      if (!balStopped) {
        setBallRotation((prevRotation) => prevRotation - 1);
      }
    }, 10);
  }

  function SingleNum({ num }) {
    return (
      <RColumn>
        {num <= 6 && (
          <div style={{ height: "26px" }}>
            {selectedBets[num] && (
              <RText>{(selectedBets[num] / usdPerToken).toFixed(1)}</RText>
            )}
          </div>
        )}
        <div
          onClick={() => onSelectBetType(num)}
          style={{
            backgroundColor: selectedBets[num] ? ColorPalette.accentColor : "",
            border: "2px solid white",
            padding: "6px",
            width: "30px",
            borderRadius: "6px",
          }}
        >
          <RText weight="bold" size="18px" align={"center"}>
            {num}
          </RText>
        </div>
        {num > 6 && (
          <div style={{ height: "26px" }}>
            {selectedBets[num] && (
              <RText>{(selectedBets[num] / usdPerToken).toFixed(1)}</RText>
            )}
          </div>
        )}
      </RColumn>
    );
  }

  function SelectBetModal() {
    function onSelect(price) {
      if (userBalance < price / usdPerToken + getTotalAMountBetted()) {
        showErrorNotification("Insufficent balance");
        return;
      }

      setSelectedBets((p) => ({ ...p, [isSelectingBet]: price }));
      setIsSelectingBet(null);
    }

    return (
      <div
        style={{
          zIndex: 10,
          backgroundColor: "rgba(0,0,0,0.5)",
          position: "fixed",
          top: "0px",
          width: "100%",
          height: "100vh",
        }}
      >
        <div
          style={{
            borderRadius: "20px 20px 0px 0px",
            position: "absolute",
            bottom: "0px",
            width: "100%",
            height: "500px",
            backgroundColor: ColorPalette.bgColor,
          }}
        >
          <Space />
          <RColumn>
            <RTitile>Select bet amount</RTitile>
            <div
              onClick={() => {
                setIsSelectingBet(null);
              }}
              style={{ position: "absolute", top: "12px", right: "12px" }}
            >
              <RText weight="bold">X</RText>
            </div>
            <Space />
            <div
              style={{
                flexWrap: "wrap",
                display: "flex",
                alignContent: "center",
                justifyContent: "center",
              }}
            >
              {betPrices.map((price, ind) => (
                <div
                  key={ind}
                  onClick={() => onSelect(price)}
                  style={{
                    border: "2px solid " + ColorPalette.accentColor,
                    padding: "6px 0px",
                    width: "40%",
                    margin: "4px 4px",
                    borderRadius: "8px",
                  }}
                >
                  <RRow horizontalAlign="center">
                    <RTitile size="18px" align={"center"}>
                      {(price / usdPerToken).toFixed(2)}
                    </RTitile>
                    <SpaceHorizontal />
                    <RText>{tokenSymbol}</RText>
                  </RRow>
                </div>
              ))}
            </div>
          </RColumn>
        </div>
      </div>
    );
  }

  function onSelectBetType(type) {
    if (isLoading || isSpinning) return;
    setIsSelectingBet(type);
    //  setSelectedBet(type);
  }
  function getTotalAMountBetted() {
    let totB = 0;
    Object.values(selectedBets).map((val, ind) => {
      totB += val;
    });

    return totB / usdPerToken;
  }
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
      />
      {isSelectingBet && <SelectBetModal />}
      <div style={{ backgroundColor: "rgb(31, 119, 17)", height: "100vh" }}>
        <RColumn verticalAlignment="start" height={"90%"}>
          <BackToHomeBtn />
          <Space height="20px" />
          <RTitile>Roulette</RTitile>
          <div style={{ position: "fixed", right: "12px", top: "22px" }}>
            <RText weight="bold">
              {userBalance.toFixed(2)} {tokenSymbol}
            </RText>
          </div>
          <Space height="50px" />
          <div
            style={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              width: isMobile ? "100%" : "80%",
              justifyContent: "center",
              alignItems: "center",
              height: "90%",
            }}
          >
            <div className="roulette-container">
              <div
                className="roulette"
                style={{ transform: `rotate(${rotation}deg)` }}
              ></div>
              <div
                className="ball"
                style={{
                  transform: `translate(-50%, -50%) translate(${ballPosition.x}px, ${ballPosition.y}px)`,
                }}
              ></div>
            </div>
            {!isMobile && <SpaceHorizontal width="30px" />}
            <RColumn
              verticalAlignment="center"
              width={isMobile ? "90%" : "400px"}
            >
              <Space />
              <RRow width={"100%"} horizontalAlign="space-between">
                {Array.from({ length: 6 }, (n, index) => (
                  <SingleNum key={index} num={index + 1} />
                ))}
              </RRow>
              <Space height="12px" />
              <RRow width={"100%"} horizontalAlign="space-between">
                {Array.from({ length: 6 }, (n, index) => (
                  <SingleNum key={index} num={index + 7} />
                ))}
              </RRow>
              <Space height="12px" />

              <RRow width={"100%"}>
                <RColumn width={"49%"}>
                  <div style={{ height: "26px" }}>
                    {selectedBets["red"] && (
                      <RText>
                        {(selectedBets["red"] / usdPerToken).toFixed(1)}
                      </RText>
                    )}
                  </div>
                  <div
                    onClick={() => onSelectBetType("red")}
                    style={{
                      backgroundColor: selectedBets["red"]
                        ? ColorPalette.accentColor
                        : "#CE2D31",
                      border: "2px solid white",
                      padding: "6px",
                      width: "100%",
                      borderRadius: "6px",
                    }}
                  >
                    <RText align={"center"} weight="bold" size="18px">
                      Red
                    </RText>
                  </div>
                </RColumn>
                <SpaceHorizontal width="20px" />
                <RColumn width={"49%"}>
                  <div style={{ height: "26px" }}>
                    {selectedBets["black"] && (
                      <RText>
                        {(selectedBets["black"] / usdPerToken).toFixed(1)}
                      </RText>
                    )}
                  </div>
                  <div
                    onClick={() => onSelectBetType("black")}
                    style={{
                      backgroundColor: selectedBets["black"]
                        ? ColorPalette.accentColor
                        : "#100F0F",
                      border: "2px solid white",
                      padding: "6px",
                      width: "100%",
                      borderRadius: "6px",
                    }}
                  >
                    <RText align={"center"} weight="bold" size="18px">
                      Black
                    </RText>
                  </div>
                </RColumn>
              </RRow>
              <Space />
              {Object.keys(selectedBets).length > 0 && (
                <>
                  {/*  <RTitile>{(selectedBetAmount / usdPerToken).toFixed(2) ?? "-"} {tokenSymbol} on {selectedBet}</RTitile> */}
                  <RRow>
                    <RText size="18px">
                      Total:{" "}
                      <strong>{getTotalAMountBetted().toFixed(2)}</strong>{" "}
                      {tokenSymbol}
                    </RText>
                    <SpaceHorizontal width="20px" />
                    <div onClick={() => setSelectedBets({})}>
                      <RText>Clear bets</RText>
                    </div>
                  </RRow>
                  <Space height="8px" />
                  {!isSpinning && !isLoading && (
                    <PrimaryBtn text={"SPIN"} onClick={play}></PrimaryBtn>
                  )}
                </>
              )}
            </RColumn>
          </div>
        </RColumn>
      </div>
    </>
  );
}
