import { initializeApp } from "firebase/app";
import { getAuth, signOut, signInWithCustomToken } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getDatabase } from "firebase/database";
import { getFunctions } from "firebase/functions";
import { getAnalytics } from "firebase/analytics";

const config = {
  apiKey: "AIzaSyANnqaI1ZgyxdYN2wx3JM3JY6j3lA_YZEQ",
  authDomain: "html5-gaming-bot.firebaseapp.com",
  projectId: "html5-gaming-bot",
  storageBucket: "html5-gaming-bot.appspot.com",
  messagingSenderId: "150263918272",
  appId: "1:150263918272:web:5244042c40e92e686d4725",
  measurementId: "G-EQ4WKB7CCK",
};

const app = initializeApp(config);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const rdb = getDatabase(app);
export const functions = getFunctions(app);
export const analytics = getAnalytics(app);

export const signInToken = async (token) => {
  try {
    await signInWithCustomToken(auth, token);
  } catch (e) {
    throw new Error("invalid token");
  }
};

export const logOut = async () => {
  await signOut(auth);
};

export default app;
